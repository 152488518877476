<template>
  <div>
    <v-dialog
      max-width="667px"
      persistent
      v-model="show"
      content-class="welcome"
    >
      <div class="welcome_body">
        <eewc-button-common
          v-if="!hovered"
          button
          icon
          type="clear"
          data-testid="welcome-screen_close"
          class="welcome_close"
          @click="closeWelcome"
          append-icon="$icon_close"
        />
        <eewc-carousel-screen
          :prevButton="t('Back')"
          :nextButton="t('Next')"
          :skipButton="t('Skip')"
          :doneButton="t('Done')"
          :totalItems="6"
          @closeScreen="closeWelcome"
          class="welcome_carousel"
          :class="{ 'hide-class': hovered }"
        >
          <template #carouselItems>
            <v-carousel-item>
              <v-card class="welcome_background">
                <v-card-title class="welcome_title">{{ t('Welcome to the enhanced web interface beta') }}</v-card-title>
                <v-card-subtitle class="pt-3 ml-4 mr-5 pb-5 body-2 welcome_subtitle">{{
                  t(
                    'We are excited to introduce the updated and improved Eagle Eye web interface, which is designed to enhance your video surveillance experience. Start exploring it today and notice the difference.'
                  )
                }}</v-card-subtitle>
                <v-card-text class="welcome_text subtitle-2">
                  <div>
                    <span class="welcome_icon"><v-icon color="accent">$icon_check_zero</v-icon></span>
                    {{ t('Modern interface designed for enhanced usability and visual appeal.') }}
                  </div>
                  <div>
                    <span class="welcome_icon"><v-icon color="accent">$icon_check_zero</v-icon></span
                    >{{ t('Capabilities that empower you to accomplish more.') }}
                  </div>
                  <div>
                    <span class="welcome_icon"><v-icon color="accent">$icon_check_zero</v-icon></span
                    >{{ t('New features crafted to streamline tasks.') }}
                  </div>
                </v-card-text>
              </v-card>
              ></v-carousel-item
            >

            <v-carousel-item class="welcome_item">
              <v-card class="welcome_background">
                <v-card-title class="welcome_title">{{ t('Multi-camera playback') }}</v-card-title>
                <v-card-subtitle class="pt-3 ml-4 mr-5 pb-5 body-2 welcome_subtitle">{{
                  t('This feature is available only in the new web interface.')
                }}</v-card-subtitle>
                <v-card-text class="welcome_grid body-2">
                  <div>
                    <p>
                      {{
                        t(
                          'Multi-camera playback enhances surveillance capabilities and enables users to better manage security incidents.'
                        )
                      }}
                    </p>
                    <p>
                      {{
                        t(
                          'Simultaneously review footage from multiple cameras, thereby enhancing overall incident management through the VMS.'
                        )
                      }}
                    </p>
                  </div>
                  <div>
                    <v-img
                      width="320px"
                      height="235px"
                      @mouseover="hovered = true"
                      @mouseout="hovered = false"
                      class="welcome_image"
                      :class="{ 'expand-on-hover': hovered }"
                      :src="require('./assets/1.png')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <eewc-loading-spinner
                            :is-loading="true"
                            :size="30"
                            color="primary"
                          />
                        </v-row> </template
                    ></v-img>
                  </div>
                </v-card-text>
              </v-card>
            </v-carousel-item>
            <v-carousel-item class="welcome_item">
              <v-card class="welcome_background">
                <v-card-title class="welcome_title">{{ t('History browser + Live view') }}</v-card-title>
                <v-card-subtitle class="pt-3 ml-4 mr-5 pb-5 body-2 welcome_subtitle">{{
                  t('This feature is available only in the new web interface.')
                }}</v-card-subtitle>
                <v-card-text class="welcome_grid body-2">
                  <div>
                    <p>
                      {{
                        t(
                          'View current and past activity in a single interface with seamlessly integrated live camera feeds and historical playback.'
                        )
                      }}
                    </p>
                  </div>
                  <div>
                    <v-img
                      width="320px"
                      height="235px"
                      class="welcome_image"
                      @mouseover="hovered = true"
                      @mouseout="hovered = false"
                      :class="{ 'expand-on-hover': hovered }"
                      :src="require('./assets/2.png')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <eewc-loading-spinner
                            :is-loading="true"
                            :size="30"
                            color="primary"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </v-card-text>
              </v-card>
            </v-carousel-item>
            <v-carousel-item class="welcome_item">
              <v-card class="welcome_background">
                <v-card-title class="welcome_title">{{ t('Notification history') }}</v-card-title>
                <v-card-subtitle class="pt-3 ml-4 mr-5 pb-5 body-2 welcome_subtitle">{{
                  t('This feature is available only in the new web interface and mobile app.')
                }}</v-card-subtitle>
                <v-card-text class="welcome_grid body-2">
                  <div>
                    <p>
                      {{
                        t(
                          'Receive alerts for past notifications to catch and review critical missed events, ensuring comprehensive awareness of security incidents.'
                        )
                      }}
                    </p>
                    <p>
                      {{
                        t(
                          'View video notifications (analytics and motion) and system health notifications for offline devices.'
                        )
                      }}
                    </p>
                  </div>
                  <div>
                    <v-img
                      width="320px"
                      height="235px"
                      class="welcome_image"
                      @mouseover="hovered = true"
                      @mouseout="hovered = false"
                      :class="{ 'expand-on-hover': hovered }"
                      :src="require('./assets/3.png')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <eewc-loading-spinner
                            :is-loading="true"
                            :size="30"
                            color="primary"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </v-card-text>
              </v-card>
            </v-carousel-item>

            <!-- <v-carousel-item class="welcome_item">
              <v-card class="welcome_background">
                <v-card-title class="welcome_title">{{ t('Password-protected link sharing') }}</v-card-title>
                <v-card-subtitle class="pt-3 ml-4 mr-5 pb-5 body-2 welcome_subtitle">{{
                  t('This feature is available only in the new web interface.')
                }}</v-card-subtitle>
                <v-card-text class="welcome_grid body-2">
                  <div>
                    <p>
                      {{
                        t(
                          'We’ve added additional security and now require a password for shared video links. Ensure that only authorized individuals can view archived footage.'
                        )
                      }}
                    </p>
                  </div>
                  <div>
                    <v-img
                      width="320px"
                      height="235px"
                      class="welcome_image"
                      @mouseover="hovered = true"
                      @mouseout="hovered = false"
                      :class="{ 'expand-on-hover': hovered }"
                      :src="require('./assets/4.png')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <eewc-loading-spinner
                            :is-loading="true"
                            :size="30"
                            color="primary"
                          />
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </v-card-text>
              </v-card>
            </v-carousel-item> -->
            <v-carousel-item>
              <v-card class="welcome_background">
                <v-card-title class="welcome_title">{{ t('Available features') }}</v-card-title>
                <v-card-subtitle class="pt-3 ml-4 mr-5 pb-5 body-2 welcome_subtitle">{{
                  t(
                    'These are available in the new web interface. More functionality will continue to be added over time and don’t worry, if you run into any issues or missing functionality, you can simply go back to the classic interface.'
                  )
                }}</v-card-subtitle>
                <v-card-text class="subtitle-2">
                  <div class="welcome_table">
                    <div>
                      <div class="pb-5">
                        <span class="welcome_icon mr-3"><v-icon color="accent">$icon_camera</v-icon></span
                        >{{ t('Live Video') }}
                      </div>
                      <div class="pb-5">
                        <span class="welcome_icon mr-3"><v-icon color="accent">$icon_layouts</v-icon></span
                        >{{ t('Layouts/Tags') }}
                      </div>
                      <div class="pb-5">
                        <span class="welcome_icon mr-3"><v-icon color="accent">$icon_analytics_down</v-icon></span
                        >{{ t('Export') }}
                      </div>
                    </div>
                    <div>
                      <div class="pb-5">
                        <span class="welcome_icon mr-3"><v-icon color="accent">$icon_history</v-icon></span
                        >{{ t('History Browser') }}
                      </div>
                      <div class="pb-5">
                        <span class="welcome_icon mr-3"><v-icon color="accent">$icon_map</v-icon></span
                        >{{ t('Maps') }}
                      </div>
                      <div class="pb-5">
                        <span class="welcome_icon mr-3"><v-icon color="accent">$icon_archive</v-icon></span
                        >{{ t('Archive') }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-carousel-item>
            <v-carousel-item>
              <v-card class="welcome_background">
                <v-card-title class="welcome_title">{{ t('Prepare for enhanced video surveillance') }}</v-card-title>
                <v-card-subtitle class="pt-3 ml-4 mr-5 pb-5 body-2 welcome_end">
                  {{
                    t(
                      'Immerse yourself in the new web interface and experience all the new features. If you would like to return to the classic one while existing features are brought live and any issues are resolved, select the toggle button at any time.'
                    )
                  }}
                  <div class="subtitle-2 pt-10">{{ t('Have questions or need assistance?') }}</div>
                  <div class="body-2">
                    {{ t('Reach out to us via {email}', { email: 'productfeedback@een.com' }) }}
                  </div>
                </v-card-subtitle>
                <!-- <eewc-button-common id="jimmy" color="accent" class="welcome_done"> Done </eewc-button-common> -->
              </v-card>
            </v-carousel-item>
          </template>
        </eewc-carousel-screen>
      </div>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, ref, watch } from 'vue';
import { t } from '@/plugins/i18n.ts';
const props = withDefaults(
  defineProps<{
    show: boolean;
  }>(),
  {
    show: true,
  }
);
const closeWelcome = () => {
  show.value = false;
  emit('close', true);
};
const hovered = ref(false);
const emit = defineEmits<{
  (e: 'close', value: boolean): void;
}>();
const show = ref(props.show);
</script>
<style lang="scss">
@import '../../styles/public/main.scss';

.expand-on-hover {
  transition: transform 0.3s ease-in-out;
  transform: scale(2.5) translateX(85px) translateY(-15px);
}

.hide-class {
  .v-window__next,
  .v-carousel__controls {
    display: none;
  }
}

.welcome {
  overflow-y: unset !important;
  &_body {
    position: relative;
  }
  &_close {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 10;
  }
  &_carousel {
    overflow: unset !important;
  }
  &_background {
    height: 100%;
  }
  &_text {
    color: $primary !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 180px;
    div {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
      span {
        margin-right: 8px;
      }
    }
  }
  &_icon {
    display: inline-block;
    background: $accentClear;
    height: 24px;
    width: 24px;
    border-radius: 4px;
  }
  &_title {
    padding-top: 52px;
    margin-left: 16px;
    @include headline-4;
  }
  &_grid {
    color: $primary !important;
    padding-left: 32px;
    padding-right: 40px;
    display: grid;
    grid-template-columns: 1.1fr 1fr;
    gap: 31px;
  }
  &_item {
    div {
      overflow: unset !important;
    }
  }
  &_table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 78.5px;
    justify-items: center;
    color: $primary !important;
  }
  &_subtitle {
    color: $secondary !important;
  }
  &_end {
    color: $primary !important;
  }
  &_image {
    box-shadow: 0px 1px 2px #25293266;
  }
  &_done {
    position: absolute;
    bottom: 16px;
    right: 14px;
  }
}
</style>
