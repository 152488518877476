import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import _ from 'lodash';
import {
  ApiBridgeWithIncludes,
  ApiCameraWithIncludes,
  GetBridgeParams,
  GetLocationParams,
  LocationGet,
} from '@eencloud/eewc-components/src/service/api-types';
import { Item } from '@eencloud/eewc-components/src/components/dropdowns/types';

import { useLPREventsStore } from './lprEvents';
import { useBridgesStore } from './bridges';
import { useCamerasStore } from './cameras';
import { useLocationsStore } from './locations';

import { VSPRoute } from '@/pages/VSP/types';
import { getStringDropdownOption } from '@/pages/VSP/utils';

/**
 * Types
 */

type VSPRouteDependency = 'locations' | 'bridges' | 'cameras';

/**
 * Store
 */

export const useVSPStore = defineStore('vsp', () => {
  const cameras = ref<ApiCameraWithIncludes[]>([]);
  const isLoadingCameras = ref<boolean>(true);
  const lprEventsStore = useLPREventsStore();
  const bridgesStore = useBridgesStore();
  const camerasStore = useCamerasStore();
  const locationsStore = useLocationsStore();
  const isVSPReportsEnabled = ref<boolean>(false);

  const getAllVSPEnabledCameras = async () => {
    const CAMERA_INCLUDES: string[] = ['status', 'locationSummary', 'deviceAddress', 'packages'];

    try {
      isLoadingCameras.value = true;

      await camerasStore.getAllCamerasByCustomParam({
        include: CAMERA_INCLUDES.toString(),
        packages__contains: 'een.vsp',
        'shareDetails.shared': false,
      });

      cameras.value = camerasStore.allCamerasByCustomParam;
    } catch {
      cameras.value = [];
    } finally {
      isLoadingCameras.value = false;
    }
  };

  const bridges = ref<ApiBridgeWithIncludes[]>([]);
  const isLoadingBridges = ref<boolean>(false);

  const getAllBridges = async (params: GetBridgeParams) => {
    try {
      isLoadingBridges.value = true;

      bridges.value = await bridgesStore.getAllBridgesByCustomParam(params);
    } catch {
      bridges.value = [];
    } finally {
      isLoadingBridges.value = false;
    }
  };

  const locations = ref<LocationGet[]>([]);
  const isLoadingLocations = ref<boolean>(false);

  const getAllLocations = async (params?: GetLocationParams) => {
    try {
      isLoadingLocations.value = true;

      const queryParams = params ?? {};

      await locationsStore.getAllLocationsByCustomParam(queryParams);

      locations.value = locationsStore.locations;
    } catch {
      locations.value = [];
    } finally {
      isLoadingLocations.value = false;
    }
  };

  const getRouteDependencies = (route: VSPRoute) => {
    const ROUTE_API_DEPENDENCIES: Record<VSPRoute, VSPRouteDependency[]> = {
      addRule: ['cameras', 'locations'],
      addVehicle: [],
      alerts: ['cameras'],
      editRule: ['cameras', 'locations'],
      editVehicle: [],
      rules: ['cameras', 'locations'],
      search: ['cameras'],
      singleVehicleList: ['cameras'],
      summary: ['bridges', 'cameras'],
      vehicleList: ['cameras', 'locations'],
      vehicleListCSVUpload: [],
    };

    const currentRouteAPIDependencies = ROUTE_API_DEPENDENCIES[route];

    currentRouteAPIDependencies?.forEach((dependency) => {
      switch (dependency) {
        case 'cameras':
          if (!cameras.value.length) {
            getAllVSPEnabledCameras();
          }
          break;

        case 'locations':
          if (!locations.value.length && lprEventsStore.isLocationGroupingEnabled) {
            getAllLocations();
          }
          break;

        case 'bridges':
          if (!bridges.value.length) {
            getAllBridges({});
          }
          break;
      }
    });
  };

  // Cameras array converted into map object
  const camerasInfoMap = computed<Record<string, ApiCameraWithIncludes>>(() => {
    const map: Record<string, ApiCameraWithIncludes> = {};

    cameras.value.forEach((camera) => (map[camera.id] = camera));

    return map;
  });

  // Locations array converted into map object
  const locationsInfoMap = computed<Record<string, LocationGet>>(() => {
    const map: Record<string, LocationGet> = {};

    locations.value.forEach((location) => {
      const id = location.id;

      if (id) {
        map[id] = location;
      }
    });

    return map;
  });

  // Bridges array converted into map object
  const bridgesInfoMap = computed<Record<string, ApiBridgeWithIncludes>>(() => {
    const map: Record<string, ApiBridgeWithIncludes> = {};

    bridges.value.forEach((bridge) => (map[bridge.id] = bridge));

    return map;
  });

  const cameraFilterOptions = computed(() => {
    const options: Item[] = [];

    cameras.value.forEach((cameraID) => {
      const camera = camerasInfoMap.value[cameraID.id];

      if (camera) {
        options.push(
          getStringDropdownOption({
            text: camera.name,
            value: cameraID.id,
          })
        );
      }
    });

    return [
      {
        name: '',
        items: _.sortBy(options, ['text']),
      },
    ];
  });

  const locationAndChildCameraFilterOptions = computed<Record<string, Item[]>>(() => {
    const map: Record<string, Item[]> = {};

    cameraFilterOptions.value[0].items.forEach((option) => {
      const cameraID = option.value;
      const camera = camerasInfoMap.value[cameraID];

      if (camera) {
        const locationName = lprEventsStore.isLocationGroupingEnabled
          ? camera.locationSummary?.name?.trim() // To trim empty spaced values "", " ", "   " etc.
          : camera.deviceAddress?.name?.trim();

        if (locationName !== undefined) {
          if (map[locationName]) {
            map[locationName].push(option);
          } else {
            map[locationName] = [option];
          }
        }
      }
    });

    return map;
  });

  const locationFilterOptions = computed(() => {
    const locationFilterMap: Record<string, Item> = {};
    cameraFilterOptions.value[0]?.items?.forEach((option) => {
      const cameraID = option.value;
      const camera = camerasInfoMap.value[cameraID];
      const locationID = camera.locationSummary?.id;
      const locationName = lprEventsStore.isLocationGroupingEnabled
        ? camera.locationSummary?.name
        : camera.deviceAddress?.name;
      const shouldUseLocationSummary = lprEventsStore.isLocationGroupingEnabled && locationID;

      if (shouldUseLocationSummary && !locationFilterMap[locationID]) {
        locationFilterMap[locationID] = getStringDropdownOption({
          text: locationName ?? locationID,
          value: locationID,
        }); // Use locationSummary if location grouping is on
      } else if (!shouldUseLocationSummary && locationName && !locationFilterMap[locationName]) {
        locationFilterMap[locationName] = getStringDropdownOption({ text: locationName, value: locationName }); // Use deviceAddress if location grouping is off
      }
    });
    const options: Item[] = Object.values(locationFilterMap);

    return [
      {
        name: '',
        items: _.sortBy(options, ['text']),
      },
    ];
  });

  const enableReports = (data: boolean) => {
    isVSPReportsEnabled.value = data;
  };

  return {
    bridges,
    bridgesInfoMap,
    cameras,
    cameraFilterOptions,
    camerasInfoMap,
    getAllBridges,
    getAllLocations,
    getAllVSPEnabledCameras,
    getRouteDependencies,
    isLoadingBridges,
    isLoadingCameras,
    isLoadingLocations,
    locations,
    locationAndChildCameraFilterOptions,
    locationFilterOptions,
    locationsInfoMap,
    isVSPReportsEnabled,
    enableReports,
  };
});
