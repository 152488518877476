import api from '@eencloud/eewc-components/src/service/api';
import { MultiCameraSettings, MultiCameraSettingsQueryParams } from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';

export const useMultiCameraSettingsStore = defineStore('multiCameraSettings', () => {
  async function getMultiCameraSettings(params: MultiCameraSettingsQueryParams, id: string) {
    const res = await api.getMultiCameraSettings(id, params);
    return res?.data;
  }

  async function updateMultiCameraSettings(payload: MultiCameraSettings, multiCameraId: string) {
    return await api.updateMultiCameraSettings(multiCameraId, { data: payload });
  }
  return { getMultiCameraSettings, updateMultiCameraSettings };
});
