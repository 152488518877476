import _ from 'lodash';
import {
  LPRActorFilter,
  LPRActorType,
  LPRInterval,
  LPRRuleTypes,
} from '@eencloud/eewc-components/src/service/api-types';

import { NewUserData } from './vehicleList/types';
import { LocationAndCamerasSummaryTableData } from './summary/types';
import { t } from '@/plugins/i18n';
import { LPRAlertConditionRuleWithLocationsAndCamerasField } from './types';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

/**
 * To get LPR actor for API call query parameter
 *
 * @param type Actor type
 * @param id Actor id
 * @returns LPR actor as query parameter
 */

const getLPRActorForQueryParam = (type: 'camera' | 'location', id: string) => `${type}:${id}`;

/**
 * To get user data key value pair from a string
 *
 * @param data User data string
 * @returns User data key value pair
 */

const getLPRUserDataKeyValuePair = (data: string) => {
  const FORWARD_SLASH = '/';

  return data.split(FORWARD_SLASH);
};

/**
 * To get user data for API payload
 *
 * @param userData Array of user data strings
 * @returns User data for API payload
 */

const getLPRUserDataForAPIPayload = (userData: string[]) => {
  const userDataRecord = userData.reduce<Record<string, string>>((acc, data) => {
    const [key, value] = getLPRUserDataKeyValuePair(data);

    if (key && value) {
      acc[key] = value;
    }

    return acc;
  }, {});

  return userDataRecord;
};

/**
 * To convert JS date to LPR event timestamp
 *
 * @param dateTime JS Date
 * @returns LPR event timestamp format for the given JS date
 */

const getLPREventTimestamp = (dateTime: Date) => {
  // Converts 2006-01-02T15:04:05.000Z => 20060102150405.000
  const TIMESTAMP_FORMATTER_REGEX = /[-ZT:]/g;

  return dateTime.toISOString().replace(TIMESTAMP_FORMATTER_REGEX, '');
};

/**
 * To find a data in LPR event response
 *
 * @param type Type of the data
 * @param data LPR event data
 * @returns Matching data
 */

const findLPREventData = (type: string, data: any[]) => {
  return data.find((data: any) => data?.type === type);
};

/**
 * To get table cell value
 *
 * @param value API response value for a table cell
 * @returns Table cell value for the response
 */

const getTableCellValue = (value: string | number | undefined | null | boolean) => {
  if (value === '') {
    return '—';
  }

  if (value === true) {
    return 'Yes';
  } else if (value === false) {
    return 'No';
  }

  return String(value ?? '—');
};

/**
 * To get a string dropdown option
 *
 * @param option Text and value
 * @returns String dropdown option
 */

const getStringDropdownOption = ({ text, value }: { text: string; value: string }) => ({
  text,
  value,
});

/**
 * To get LPR actor
 *
 * @param type Actor type
 * @param id Actor id
 * @returns LPR actor
 */

const getLPRActor = ({ type, id }: { type: 'camera' | 'location'; id: string }) => ({ id, type });

/**
 * To get location and camera IDs from actors
 *
 * @param actors Actors with type and id
 * @returns locations IDs and camera IDs
 */

const getLocationAndCameraIDsFromActors = (actors: LPRActorFilter[] | undefined) => {
  const cameraIDs: string[] = [];
  const locationIDs: string[] = [];

  if (actors) {
    actors.forEach((actor) => {
      const actorID = actor.id;

      if (actorID && actor.type === LPRActorType.Camera) {
        cameraIDs.push(actor.id as string);
      } else if (actorID && actor.type === LPRActorType.Location) {
        locationIDs.push(actor.id as string);
      }
    });
  }

  return { cameraIDs, locationIDs };
};

/**
 * To get mapped LPR alert type
 *
 * @param type Alert type
 * @returns LPR alert type
 */

const getMappedLPRAlertType = (type: LPRRuleTypes) => {
  const MAP = {
    [LPRRuleTypes.LprRuleAccessControlAllow]: 'Allowed vehicle',
    [LPRRuleTypes.LprRuleAccessControlDeny]: 'Denied vehicle',
    [LPRRuleTypes.LprRuleWatch]: 'Watch vehicle',
    [LPRRuleTypes.LprRuleHotlist]: 'Hotlist',
    [LPRRuleTypes.LprRuleCountAggr]: 'Count of license plate',
    [LPRRuleTypes.LprRuleUnlisted]: 'Unregistered vehicle',
  };

  return MAP[type] ?? '';
};

/**
 * Get empty select dropdown option
 *
 * @param text Empty select dropdown option text
 * @returns Empty select dropdown option
 */

const getEmptySelectOption = (text: string) => ({
  text,
  value: '',
});

/**
 * Get priority range text
 *
 * @param priority Priority value
 * @returns Priority range text
 */

const getPriorityRangeText = (priority: number) => {
  return _.inRange(priority, 1, 4)
    ? 'low'
    : _.inRange(priority, 4, 7)
    ? 'medium'
    : _.inRange(priority, 7, 10)
    ? 'high'
    : 'alarm';
};

/**
 * To check if license plate number is valid or not
 *
 * @param plateNumber License plate number
 * @returns true/false
 */

const checkIfLicensePlateNumberIsValid = (plateNumber: string) => /^[0-9]*[A-Z0-9]+$/.test(plateNumber);

/**
 * To check if input value is greater than zero or not
 *
 * @param numberInput Number
 * @returns true/false
 */

const checkIfValueIsGreaterThanZero = (value: number) => value > 0;

/**
 * To get user data
 *
 * @param userData User data label & value
 * @returns user data
 */

const getUserData = ({ label, value }: NewUserData) => ({
  label,
  value,
});

/**
 * To get LPR interval
 *
 * @param interval Interval start & end
 * @returns LPR interval
 */

const getLPRInterval = ({ start, end }: LPRInterval): LPRInterval => ({
  start,
  end,
});

/**
 * Get sanitized plate number
 *
 * @param plateNumber Plate number
 * @returns Sanitized plate number
 */

const getSanitizedPlateNumber = (plateNumber: string) => {
  const SANITIZER_REGEX = /[^A-Za-z0-9*-]/g; // To consider only alpha numeric, * and -
  const sanitizedPlateNumber = plateNumber.replace(SANITIZER_REGEX, '').toUpperCase(); // Also convert characters to uppercase

  return sanitizedPlateNumber;
};

/**
 * Get seconds from midnight
 *
 * @param dateTime Date time
 * @returns time from midnight in seconds
 */

const getSecondsFromMidnight = (dateTime: Date) =>
  dateTime.getHours() * 60 * 60 + dateTime.getMinutes() * 60 + dateTime.getSeconds();

/**
 * Get date type from time string
 *
 * @param time time from midnight in seconds
 * @returns Date time
 */

const parseDurationString = (duration: string) => {
  // Regular expression to match the duration string in the format of a number followed by 's', 'm', or 'h'
  const PARSE_DURATION_STRING_REGEX = /(\d+)([smh])/gi;

  // Extract an array of matched substrings from the input string based on the regular expression
  const timeDurations = duration.match(PARSE_DURATION_STRING_REGEX);

  let totalMilliseconds = 0;

  const MILLISECOND_DURATION = {
    second: 1000,
    minute: 1000 * 60,
    hour: 1000 * 60 * 60,
  };

  if (timeDurations?.length) {
    for (const duration of timeDurations) {
      // Extract the numeric value and unit from the matched duration
      const value = parseInt(duration.slice(0, -1), 10);
      const unit = duration.slice(-1);

      const TIME_UNITS = {
        second: 's',
        minute: 'm',
        hour: 'h',
      };

      switch (unit) {
        case TIME_UNITS.second:
          totalMilliseconds += value * MILLISECOND_DURATION.second;
          break;

        case TIME_UNITS.minute:
          totalMilliseconds += value * MILLISECOND_DURATION.minute;
          break;

        case TIME_UNITS.hour:
          totalMilliseconds += value * MILLISECOND_DURATION.hour;
          break;

        default:
          break;
      }
    }
  }

  const currentDate = new Date();

  const currentTimeInMilliseconds =
    currentDate.getHours() * MILLISECOND_DURATION.hour +
    currentDate.getMinutes() * MILLISECOND_DURATION.minute +
    currentDate.getSeconds() * MILLISECOND_DURATION.second;

  // Create a new Date object and set it to the current date minus the current time plus the total parsed duration
  const dateTime = new Date(currentDate);
  dateTime.setTime(currentDate.getTime() - currentTimeInMilliseconds + totalMilliseconds);

  return dateTime;
};

/**
 * Check if priority is greater than or equal to ten
 *
 * @param priority Priority
 * @returns If priority is greater than or equal to ten or not
 */

const checkIfPriorityIsOfAlarmType = (priority: number) => priority >= 10;

/**
 * To check if user data is valid or not
 *
 * @param userData Userdata
 * @returns true/false
 */

const checkIfUserDataIsValid = (userData: string[]) =>
  userData.every((data: string) => containsSlashNotAtStartEnd(data));

/**
 * To check string contains "/" symbol
 *
 * @param str string
 * @returns true/false
 */

const containsSlashNotAtStartEnd = (str: string) => {
  const USERDATA_STRING_FORMAT_REGEX = /^(?:\s*[^/\s][^/]*\s*\/\s*[^/\s][^/]*\s*)$/;

  return USERDATA_STRING_FORMAT_REGEX.test(str);
};

/**
 * To flatten array for location and summary table data
 *
 * @param inputArray LocationAndCamerasSummaryTableData[]
 * @returns flattened LocationAndCamerasSummaryTableData array
 */

const flattenSummaryTableData = (inputArray: LocationAndCamerasSummaryTableData[]) => {
  const outputArray: LocationAndCamerasSummaryTableData[] = [];

  function flatten(obj: LocationAndCamerasSummaryTableData) {
    outputArray.push(obj);
    if (obj.children && obj.children.length > 0) {
      obj.children.forEach((child: LocationAndCamerasSummaryTableData) => flatten(child));
    }
  }

  inputArray.forEach((obj: LocationAndCamerasSummaryTableData) => flatten(obj));

  return outputArray;
};

/**
 * To get translated default label strings
 *
 * @returns Translated default label strings
 */

const getDefaultLabelStrings = () => {
  return {
    allLabel: t('Select all'),
    searchPlaceholder: t('Search'),
    noResultsLabel: t('No results found'),
  };
};

/**
 * To get translated location label strings
 *
 * @returns Translated location label strings
 */

const getLocationLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All locations'),
    itemType: 'locations',
    noneLabel: t('Select locations'),
  };
};

/**
 * To get translated cameras label strings
 *
 * @returns Translated cameras label strings
 */

const getCamerasLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All cameras'),
    itemType: t('cameras'),
    noneLabel: t('Select cameras'),
  };
};

/**
 * To get translated actions label strings
 *
 * @returns Translated actions label strings
 */

const getActionsLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All actions'),
    itemType: t('actions'),
    noneLabel: t('Select actions'),
  };
};

/**
 * To get priority translations
 *
 * @returns priority translations
 */

const getPriorityTranslations = () => {
  return {
    from: t('from'),
    to: t('to'),
    name: t('Priority'),
  };
};

/**
 * To get translated makes label strings
 *
 * @returns Translated makes label strings
 */

const getMakesLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All makes'),
    itemType: t('makes'),
    noneLabel: t('Select makes'),
  };
};

/**
 * To get translated colors label strings
 *
 * @returns Translated colors label strings
 */

const getColorsLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All colors'),
    itemType: t('colors'),
    noneLabel: t('Select colors'),
  };
};

/**
 * To get translated directions label strings
 *
 * @returns Translated directions label strings
 */

const getDirectionsLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All directions'),
    itemType: t('directions'),
    noneLabel: t('Select directions'),
  };
};

/**
 * To get translated body type label strings
 *
 * @returns Translated body type label strings
 */

const getBodyTypeLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All vehicle types'),
    itemType: t('vehicle types'),
    noneLabel: t('Select vehicle types'),
  };
};

/**
 * To get translated access type label strings
 *
 * @returns Translated access type label strings
 */

const getAccessTypeLabelStrings = () => {
  return {
    ...getDefaultLabelStrings(),
    allSelectedLabel: t('All access types'),
    itemType: t('access types'),
    noneLabel: t('Select access types'),
  };
};

/**
 * To get translated blank option text
 *
 * @returns Translated blank option text
 */

const getBlankOptionText = () => {
  return t('Blanks');
};

/**
 *  The return type `LPRAlertConditionRuleWithLocationsAndCamerasField`.
 *  Function takes alert rule as parameter and return rule with locations and cameras keys
 */
const getAlertsRulesWithLocationsAndCameras = (rule: LPRAlertConditionRuleWithLocationsAndCamerasField) => {
  const ruleActors = rule?.eventFilter?.resourceFilter?.actors;

  const formattedRule = {
    ...rule,
    locations:
      (ruleActors
        ?.filter((actor) => actor.id && actor.type === LPRActorType.Location)
        .map((actor) => actor.id) as string[]) ?? [],
    cameras:
      (ruleActors
        ?.filter((actor) => actor.id && actor.type === LPRActorType.Camera)
        .map((actor) => actor.id) as string[]) ?? [],
  };

  return formattedRule;
};

/**
 *
 * @returns the date in the format 2022-08-01T07:17:55.115+05:30 (usertime in ISO format with offset)
 */

dayjs.extend(timezone);

const formatDateWithTimezoneOffset = (timezone: string | undefined) => {
  return dayjs().tz(timezone).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export {
  checkIfLicensePlateNumberIsValid,
  checkIfPriorityIsOfAlarmType,
  checkIfUserDataIsValid,
  checkIfValueIsGreaterThanZero,
  findLPREventData,
  flattenSummaryTableData,
  formatDateWithTimezoneOffset,
  getAccessTypeLabelStrings,
  getActionsLabelStrings,
  getAlertsRulesWithLocationsAndCameras,
  getBlankOptionText,
  getBodyTypeLabelStrings,
  getCamerasLabelStrings,
  getColorsLabelStrings,
  getDefaultLabelStrings,
  getDirectionsLabelStrings,
  getEmptySelectOption,
  getLocationAndCameraIDsFromActors,
  getLocationLabelStrings,
  getLPRActor,
  getLPRActorForQueryParam,
  getLPREventTimestamp,
  getLPRInterval,
  getLPRUserDataForAPIPayload,
  getLPRUserDataKeyValuePair,
  getMakesLabelStrings,
  getMappedLPRAlertType,
  getPriorityRangeText,
  getPriorityTranslations,
  getSanitizedPlateNumber,
  getSecondsFromMidnight,
  getStringDropdownOption,
  getTableCellValue,
  getUserData,
  parseDurationString,
  containsSlashNotAtStartEnd,
};
