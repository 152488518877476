import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiBridgeCameraCreate,
  ApiCameraWithIncludes,
  ApiUpdateBulkCamera,
  BulkUpdateResponses,
  DeviceStatus,
  ApiDevicePositionCamera,
  GetCamerasParams,
  SwapCameraParams,
  ApiAddCamera,
  ApiCameraUpdate,
  ApiCameraMain,
  CameraTuningParams,
} from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { t } from '@/plugins/i18n.ts';
import { useTagsStore, useMessagingStore, useAvailableDevicesStore, useUsersStore } from '@/stores';
import { getBusinessType } from '@/service/helpers';

export const useCamerasStore = defineStore('cameras', function () {
  const messagingStore = useMessagingStore();
  const usersStore = useUsersStore();
  const tagsStore = useTagsStore();
  // We will no longer fetch all cameras in the platform. Until we implement pagination everywhere,
  // we will maintain the 'cameras' variable. The 'pagedCameras' variable is present because we need
  // to update the camera status in the store when it is updated on the WebSocket event.
  const pagedCameras = ref<ApiCameraWithIncludes[]>([]);
  const addingCamera = ref(false);
  const dashboardCurrentPage = ref<ApiCameraWithIncludes[]>([]);
  /* This variable is temprorary storing all cameras. Do not use this without calling getAllCamerasByCustomParam().
   This variable will also be overwritten when function called again by another page.*/
  const allCamerasByCustomParam = ref<ApiCameraWithIncludes[]>([]);
  const allCamerasByCustomParamLoading = ref(false);
  const defaultIncludes = [
    'locationSummary',
    'devicePosition',
    'tags',
    'status',
    'timeZone',
    'deviceInfo',
    'effectivePermissions',
    'deviceAddress',
  ].toString();

  const cachedCameras: { [key: string]: ApiCameraMain } = {};

  const actions = {
    async updateGoogleAnalytics() {
      const params: GetCamerasParams = {
        pageSize: 1,
      };
      const { totalSize } = await actions.getPagedCameras(params);
      const businessType = getBusinessType(totalSize);
      if (businessType) {
        window.gtag('event', businessType, {
          userId: usersStore.currentUser?.id,
          accountID: usersStore.currentUser?.accountId,
        });
      }
    },
    async addCamera(payload: ApiAddCamera, showSuccessMessage: boolean = true) {
      try {
        addingCamera.value = true;
        const data = await api.addCamera(payload);
        if (data) {
          if (data.id) {
            tagsStore.resetPagedTags();
          }
          const availableDevicesStore = useAvailableDevicesStore();
          addingCamera.value = false;
          availableDevicesStore.availableDevices = availableDevicesStore.availableDevices.filter((availableDevice) => {
            const bridgeCameraCreatePayload = payload as ApiBridgeCameraCreate;
            return availableDevice.guid !== bridgeCameraCreatePayload.guid;
          });

          showSuccessMessage &&
            messagingStore.showSuccessMessage(
              t('The {cameraName} camera was successfully added to Dashboard.', { cameraName: payload.name })
            );
          return data;
        }
      } catch (error) {
        addingCamera.value = false;
        console.error(error);
      }
    },
    async getPagedCameras(params: GetCamerasParams) {
      let cameras: ApiCameraWithIncludes[] = [];
      let nextPageToken = '';
      let prevPageToken = '';
      let totalSize = 0;
      try {
        const data = await api.getCameras(params);
        if (data) {
          nextPageToken = data?.nextPageToken ?? '';
          prevPageToken = data?.prevPageToken ?? '';
          totalSize = data.totalSize ?? 0;
          cameras = data.results as ApiCameraWithIncludes[];
        }
      } catch (error) {
        console.error(error);
      }
      return { cameras, totalSize, nextPageToken, prevPageToken };
    },
    async bulkUpdateCamera(payload: ApiUpdateBulkCamera) {
      try {
        const data: BulkUpdateResponses | undefined = await api.bulkUpdateCamera(payload);
        if (data) {
          return Object.keys(data).map(function (key) {
            const dataFromKey = data[key];
            return {
              cameraId: key,
              status: dataFromKey.status,
            };
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    cameraFocusTuning(params: CameraTuningParams, id: string) {
      return api.cameraFocusTuning(params, id);
    },

    async deleteCamera(cameraId: string) {
      try {
        const result = await api.deleteCamera(cameraId);
        tagsStore.resetPagedTags();
        return result;
      } catch (error) {
        //show error notification
        console.error(error);
      }
    },

    async patchCamera(cameraId: string, payload: ApiCameraUpdate) {
      try {
        return await api.editCamera(payload, cameraId);
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * @param params GetCamerasParams
     * @description The function will fetch all cameras and save it to allCamerasByCustomParam.
     * Be careful with this function It is used in multiple places and It overrides the allCamerasByCustomParam variable.
     */
    async getAllCamerasByCustomParam(params: GetCamerasParams) {
      let camerasList: ApiCameraWithIncludes[] = [];
      allCamerasByCustomParamLoading.value = true;
      try {
        let pageToken: string | undefined;
        do {
          const data = await api.getCameras({
            pageToken: pageToken as string,
            pageSize: 500,
            ...params,
            include: params.include ? params.include.toString() : undefined,
          });
          if (data) {
            pageToken = data.nextPageToken;
            camerasList = [...camerasList, ...(data.results as ApiCameraWithIncludes[])];
          } else break;
        } while (pageToken !== '');
      } catch (error) {
        console.error(error);
      }
      allCamerasByCustomParam.value = camerasList;
      allCamerasByCustomParamLoading.value = false;
      return camerasList;
    },

    updateCameraStatus(deviceId: string, status: DeviceStatus) {
      this.updateCameraArray(pagedCameras.value, deviceId, status);
      this.updateCameraArray(allCamerasByCustomParam.value, deviceId, status);
    },

    updateCameraArray(cameraArray: ApiCameraWithIncludes[], deviceId: string, status: DeviceStatus) {
      const cameraIndex = cameraArray.findIndex((cam) => cam.id === deviceId);
      if (cameraIndex !== -1) {
        const cam = cameraArray[cameraIndex];
        cam.status = { ...cam.status, connectionStatus: status };
      }
    },

    async updateDevicePosition(payload: ApiDevicePositionCamera, deviceId: string) {
      if (Object.hasOwn(payload, 'rangeInFeet')) {
        // delete rangeInMeters, as it's currently not supported in PATCH
        // https://eagleeyenetworks.atlassian.net/browse/EEPD-28304
        payload.rangeInMeters = payload.rangeInFeet! * 0.3048;
        delete payload.rangeInMeters;
      }
      try {
        const data = await api.editCameraDevicePosition(payload, deviceId);
        if (data) {
          const camera = allCamerasByCustomParam.value.find((cam) => cam.id === deviceId);
          if (camera) camera.devicePosition = payload;
          messagingStore.addNotification({
            iconColor: 'positive',
            icon: '$icon_check_zero',
            title: t(`Location added`),
            body: ` ${t('Location has been successfully updated')}`,
          });
        }
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },

    /**
     * the function will fetch camera based on id and update the new status of it in store
     * @param deviceId - camera device id
     */
    async fetchCameraAndUpdateStatus(deviceId: string) {
      const camData = await api.getCameras({
        cameraId: deviceId,
        include: defaultIncludes,
      });
      const camera = camData as ApiCameraWithIncludes;
      camera.status?.connectionStatus && this.updateCameraStatus(deviceId, camera.status.connectionStatus);
    },

    /**
     * function which filters the cameras based on search keyword which matches  bridge name or bridge id or camera name or camera id
     *
     * @deprecated This function is deprecated. [ use cameras api and q param to filter cameras]
     * @param searchQuery: string
     * @param cameras: list of cameras in which filter operation has to be performed
     *
     * @returns the cameras list which meets the search criterion
     */
    getFilteredCameras(searchQuery: string, cameras: ApiCameraWithIncludes[]): ApiCameraWithIncludes[] {
      const filteredCameras = cameras.filter(
        (cam) =>
          cam.name.toLowerCase().includes(searchQuery) ||
          cam.id.toLowerCase().includes(searchQuery) ||
          (cam.bridgeId && cam.bridgeId.toLowerCase().includes(searchQuery))
      );

      return filteredCameras;
    },

    async getCamera(cameraId: string, payload?: GetCamerasParams) {
      if (!payload && cachedCameras[cameraId]) {
        return cachedCameras[cameraId];
      } else {
        const result = await api.getCamera(cameraId, payload);
        if (result) {
          cachedCameras[cameraId] = result;
          return result;
        }
      }
    },

    async getCameraCapabilities(cameraId: string) {
      const { capabilities } = (await api.getCameras({
        cameraId,
        include: 'capabilities',
      })) as ApiCameraWithIncludes;
      return capabilities;
    },

    async createTunnel(cameraId: string) {
      return await api.createDeviceTunnel(cameraId, 'cameras');
    },

    async deleteTunnel(cameraId: string) {
      return await api.deleteDeviceTunnel(cameraId, 'cameras');
    },

    async swapCamera(params: SwapCameraParams, cameraName?: string) {
      const response = await api.swapCamera(params);
      if (!response) return;
      messagingStore?.addNotification({
        iconColor: 'positive',
        icon: '$icon_check_zero',
        title: t('Camera added'),
        body: t('{deviceName} is now in the My devices list', {
          deviceName: cameraName,
        }),
      });
    },

    async turnOnOffCamera(cameraIds: string[], turnOn: boolean) {
      const bulkPayload = {
        ids: cameraIds,
        updateFields: {
          on: turnOn,
        },
      };
      return await this.bulkUpdateCamera(bulkPayload);
    },

    resetPagedCameras() {
      pagedCameras.value = [];
      pagedCamerasPageToken.value = undefined;
      pagedCamerasAllLoaded.value = false;
    },
  };

  return {
    pagedCameras,
    addingCamera,
    allCamerasByCustomParam,
    allCamerasByCustomParamLoading,
    dashboardCurrentPage,
    ...actions,
  };
});
