import { ref } from 'vue';
import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import APIList from '@eencloud/eewc-components/src/service/api';
import {
  VSCommonRequestBodyMeta,
  VSFaceSearchEventQueryParams,
  VSEventsDeepSearchQueryParams,
  VSEventsGroupSearchQueryParams,
  VSEventsListObjectValuesQueryParams,
  VSEventsTimeGroupSearchQueryParams,
  VSEventsTimeGroupSearchRequestPayload,
  VSMapNLQueryToAttributesQueryParams,
  VSMapNLQueryToAttributesRequestPayload,
  VSObjectMetaType,
  VSSearchEventsV1,
} from '@eencloud/eewc-components/src/service/api-types';
import { Item } from '@eencloud/eewc-components/src/components/dropdowns/types';

import {
  FSQueryParams,
  ObjectClassAttribute,
  ObjectFilters,
  VSGroupedEvent,
  VSObjectClassType,
} from '@/pages/VideoSearch/types';
import {
  DEFAULT_EVENT_INCLUDES,
  EVENTS_OBJECT_VALUES,
  EVENT_SORT_OPTIONS,
  EVENT_TYPES,
  FACE_SEARCH_QUERY_TEXT,
} from '@/pages/VideoSearch/constants';
import locale from '@/plugins/i18n.ts';
import { generateTimestamp } from '@/service/helpers';

/**
 * Constants
 */

const EVENTS_DEFAULT_PAGE_SIZE = 25;

const currentDateTime = new Date();
const oneDayBeforeDateTime = dayjs(currentDateTime).subtract(1, 'days').toDate();

/**
 * Store
 */

export const useVideoSearchStore = defineStore('videoSearch', function () {
  // All object meta type classes that are supported
  const objectClasses = ref<Array<VSObjectMetaType>>([]);

  // Key = object class, value = attributes
  const objectClassesAndAttributes = ref<ObjectClassAttribute>({});

  const isLoadingObjectClassesAndAttributes = ref(false);
  const objectClassTypes = ref<VSObjectClassType>({});

  // Flag for grouping events by camera
  const isGroupEventsByCameraEnabled = ref<boolean>(false);
  const isDensityMapEnabled = ref<boolean>(false);
  const shouldShowAllFaceEvents = ref<boolean>(false);

  // For storing events returned by both deep search and grouping calls
  const events = ref<Array<VSSearchEventsV1 | VSGroupedEvent>>([]);
  const isLoadingEvents = ref<boolean>(false);
  const eventsNextPageToken = ref<string | undefined>();
  const eventsPrevPageToken = ref<string | undefined>();
  const eventsTotalSize = ref<number | undefined>(0);
  const eventsPageSize = ref<number>(EVENTS_DEFAULT_PAGE_SIZE);
  const searchText = ref<string>('');

  // For storing face search results returned by deep search calls
  const faceSearchEvents = ref<Array<VSSearchEventsV1 | VSGroupedEvent>>([]);
  const isLoadingFaceSearchEvents = ref<boolean>(false);
  const faceSearchEventsNextPageToken = ref<string | undefined>();
  const faceSearchEventsPrevPageToken = ref<string | undefined>();
  const faceSearchEventsTotalSize = ref<number | undefined>(0);

  // For showing face crops in video search page
  const faceCropEvents = ref<Array<VSSearchEventsV1 | VSGroupedEvent>>([]);
  const isLoadingFaceCropsEvents = ref<boolean>(false);
  const faceCropsEventsNextPageToken = ref<string | undefined>();
  const faceCropsEventsPrevPageToken = ref<string | undefined>();
  const faceCropsEventsTotalSize = ref<number | undefined>(0);

  /**
   * Filters
   */

  const objectFilters = ref<ObjectFilters>([]);

  // Time
  const timeFilter = ref<[number, number]>([oneDayBeforeDateTime.getTime(), currentDateTime.getTime()]);

  const initialRange = ref<[Date, Date]>([oneDayBeforeDateTime, currentDateTime]);

  // Camera
  const cameraFilters = ref<Array<string>>([]);

  const GRID_COLUMN_COUNT = {
    three: 3,
    four: 4,
    six: 6,
  };

  const gridColumnCount = ref<number>(GRID_COLUMN_COUNT.three);

  const presetText = ref<string>('');

  const shouldShowHiddenFaceMatchFeature = ref<boolean>(false);
  const shouldFetchFaceCropsInitially = ref<boolean>(false);

  const getters = {
    sortOptions: () => {
      if (isGroupEventsByCameraEnabled.value) {
        return EVENT_SORT_OPTIONS;
      }

      return EVENT_SORT_OPTIONS.filter((option) => option.value !== '+eventCount');
    },
  };

  // sort
  const eventsDefaultSort = getters.sortOptions()[0];
  const eventsSort = ref<Item>(eventsDefaultSort);
  const elapsedTimeInSeconds = ref<number>(0);
  const eventsObjectValues = ref();

  const actions = {
    /**
     * Fetches all the object meta types that are supported + their corresponding attributes
     */

    async getObjectClassesAndAttributes() {
      try {
        isLoadingObjectClassesAndAttributes.value = true;

        const objectClassesResponse = await APIList.fetchVSObjectClasses();

        const classes = objectClassesResponse.results ?? [];

        // Fetches all the attributes for all object classes
        const classAttributesResponse = await Promise.allSettled(
          classes.map((objectClass) => APIList.fetchVSObjectClassAttributes(objectClass))
        );

        const classAttributePair: Record<string, Array<string>> = {};

        classes.forEach((objectClass, index) => {
          const attributes = classAttributesResponse[index];

          classAttributePair[objectClass] =
            attributes.status === 'fulfilled' ? (attributes.value as Array<string>) : [];
        });

        objectClassesAndAttributes.value = classAttributePair;

        Object.keys(objectClassesAndAttributes.value)?.map((key) => {
          objectClassTypes.value[key] = key;
        });

        objectClasses.value = classes;
      } catch {
        const classes: Array<VSObjectMetaType> = [];
        objectClasses.value = classes;
        window.gtag('event', 'VS_Object_Class_API_Failed');
      } finally {
        isLoadingObjectClassesAndAttributes.value = false;
      }
    },

    /**
     * Events
     */

    setIsGroupEventsByCameraEnabled(value: boolean) {
      isGroupEventsByCameraEnabled.value = value;
    },

    setIsDensityMapEnabled(value: boolean) {
      isDensityMapEnabled.value = value;
    },

    setShouldShowAllFaceEvents(value: boolean) {
      shouldShowAllFaceEvents.value = value;
    },

    setVsEvents(value: Array<VSSearchEventsV1 | VSGroupedEvent>) {
      events.value = value;
    },

    setVsEventsLoading(value: boolean) {
      isLoadingEvents.value = value;
      isLoadingFaceSearchEvents.value = value;
      isLoadingFaceCropsEvents.value = value;
    },

    setObjectFilters(value: ObjectFilters) {
      objectFilters.value = value;
    },

    setGridColumnCount(count: number) {
      gridColumnCount.value = count;
    },

    updateShowHiddenFaceMatchFeature(value: boolean) {
      shouldShowHiddenFaceMatchFeature.value = value;
      shouldFetchFaceCropsInitially.value = true;
    },

    async getEventsObjectValues(params: VSEventsListObjectValuesQueryParams) {
      try {
        const response = await APIList.fetchVSEventsObjectValues(params);

        eventsObjectValues.value = response;
      } catch (error) {
        console.log(error);
        window.gtag('event', 'VS_List_Object_Values_API_Failed');
      }
    },

    async getClusterEventsByDeepSearch(
      params: VSEventsDeepSearchQueryParams,
      payload: VSEventsTimeGroupSearchRequestPayload
    ) {
      try {
        return APIList.fetchVSEventsByDeepSearch(params, payload);
      } catch (error) {
        console.log(error);
        window.gtag('event', 'VS_Deep_Search_API_Failed');
      }
    },

    async getVSEventsByDeepSearch(
      params: VSEventsDeepSearchQueryParams,
      payload: VSEventsTimeGroupSearchRequestPayload
    ) {
      try {
        isLoadingEvents.value = true;

        const response = await APIList.fetchVSEventsByDeepSearch(params, payload);

        const results = response.results ?? [];

        events.value = results;
        eventsTotalSize.value = response.totalSize ?? results.length;
        eventsNextPageToken.value = response.nextPageToken;
        eventsPrevPageToken.value = response.prevPageToken;
      } catch (error) {
        console.log(error);
        window.gtag('event', 'VS_Deep_Search_API_Failed');
      } finally {
        isLoadingEvents.value = false;
      }
    },

    async getVSEventsByGroup(params: VSEventsGroupSearchQueryParams, payload: VSCommonRequestBodyMeta) {
      try {
        isLoadingEvents.value = true;

        const response = await APIList.fetchVSEventsByGroup(params, payload);

        const results = response.results ?? [];

        events.value = results;
        eventsTotalSize.value = response.totalSize ?? results.length;
        eventsNextPageToken.value = response.nextPageToken;
        eventsPrevPageToken.value = response.prevPageToken;
      } catch (error) {
        console.log(error);
        window.gtag('event', 'VS_Deep_Search_Group_By_Resource_API_Failed');
      } finally {
        isLoadingEvents.value = false;
      }
    },

    async getPaginatedEventsByDeepSearch(params: VSEventsDeepSearchQueryParams, payload: VSCommonRequestBodyMeta) {
      try {
        isLoadingEvents.value = true;

        const response = await APIList.fetchVSEventsByDeepSearch(params, payload);

        const results = response.results ?? [];

        events.value = events.value.concat(results);
        eventsTotalSize.value = response.totalSize ?? results.length;
        eventsNextPageToken.value = response.nextPageToken;
        eventsPrevPageToken.value = response.prevPageToken;
      } catch (error) {
        console.log(error);
        window.gtag('event', 'VS_Deep_Search__API_Failed');
      } finally {
        isLoadingEvents.value = false;
      }
    },

    async getPaginatedEventsByGroup(params: VSEventsGroupSearchQueryParams, payload: VSCommonRequestBodyMeta) {
      try {
        isLoadingEvents.value = true;

        const response = await APIList.fetchVSEventsByGroup(params, payload);

        const results = response.results ?? [];

        events.value = events.value.concat(results);
        eventsTotalSize.value = response.totalSize ?? results.length;
        eventsNextPageToken.value = response.nextPageToken;
        eventsPrevPageToken.value = response.prevPageToken;
      } catch (error) {
        console.log(error);
        window.gtag('event', 'VS_Deep_Search_Group_By_Resource_API_Failed');
      } finally {
        isLoadingEvents.value = false;
      }
    },

    async fetchVSEvents(
      sortOption?: '+timestamp' | '-timestamp',
      actorIDs?: string,
      pageToken?: string,
      selectedFilterObject?: ObjectFilters
    ) {
      const params: VSEventsDeepSearchQueryParams & VSEventsGroupSearchQueryParams = {
        pageSize: eventsPageSize.value,
        timestamp__gte: generateTimestamp(timeFilter.value[0]),
        timestamp__lte: generateTimestamp(timeFilter.value[1]),
        pageToken: pageToken,
        include: isGroupEventsByCameraEnabled.value
          ? `${DEFAULT_EVENT_INCLUDES.toString()},${
              sortOption === '-timestamp' ? EVENT_TYPES.lastSample : EVENT_TYPES.firstSample
            }`
          : DEFAULT_EVENT_INCLUDES.toString(),
        sort: sortOption,
      };

      if (actorIDs?.length) {
        params.actor__in = actorIDs;
      }

      if (selectedFilterObject) {
        objectFilters.value = selectedFilterObject;
      }

      const payload: VSCommonRequestBodyMeta = {
        objects__all: objectFilters.value,
      };

      if (isGroupEventsByCameraEnabled.value) {
        actions.getVSEventsByGroup(params, payload);
      } else {
        actions.getVSEventsByDeepSearch({ ...params }, payload);
      }
    },

    clearEvents() {
      events.value = [];
      faceSearchEvents.value = [];
      faceCropEvents.value = [];
    },

    async getAttributesForAnNLPQuery(
      params: VSMapNLQueryToAttributesQueryParams,
      payload: VSMapNLQueryToAttributesRequestPayload
    ) {
      try {
        const response = await APIList.fetchVSAttributesForAnNLPQuery(params, payload);
        const hasFaceItems = response.objects__all
          ?.flatMap((arr) => arr)
          .some((item) => item.class?.toLowerCase() === FACE_SEARCH_QUERY_TEXT.toLowerCase());

        shouldShowAllFaceEvents.value = !!hasFaceItems && shouldShowHiddenFaceMatchFeature.value;

        if (!response.objects__all?.length) {
          isLoadingEvents.value = false;
          events.value = [];
        }

        const flattenedObjectFilter = response.objects__all?.flat();

        const searchFilterObject = flattenedObjectFilter?.map((attribute) => {
          let filterAttribute = {};
          let filterObject = [];

          if (attribute.class === objectClassTypes.value.person) {
            filterAttribute = { type: EVENTS_OBJECT_VALUES.person };
          }
          if (attribute.class === objectClassTypes.value.vehicle) {
            filterAttribute = { type: EVENTS_OBJECT_VALUES.vehicle };
          }

          filterObject.push({ type: attribute.type, class: attribute.class }, filterAttribute);

          return filterObject;
        });

        if (!hasFaceItems) {
          objectFilters.value = flattenedObjectFilter?.length === 1 ? searchFilterObject : response.objects__all;
        }
      } catch (error) {
        console.log(error);
        window.gtag('event', 'VS_Parse_API_Failed');
      }
    },

    async getEventsByTimeGroup(
      params: VSEventsTimeGroupSearchQueryParams,
      payload: VSEventsTimeGroupSearchRequestPayload
    ) {
      try {
        return APIList.fetchVSEventsByTimeGroup(params, payload);
      } catch (error) {
        console.log(error);
        window.gtag('event', 'VS_Deep_Search_Group_By_Time_API_Failed');
      }
    },

    async getAllPaginatedEvents(
      sortOption?: '+timestamp' | '-timestamp',
      actorIDs?: string,
      pageToken?: string,
      selectedFilterObject?: ObjectFilters
    ) {
      const params: VSEventsDeepSearchQueryParams & VSEventsGroupSearchQueryParams = {
        pageSize: eventsPageSize.value,
        timestamp__gte: generateTimestamp(timeFilter.value[0]),
        timestamp__lte: generateTimestamp(timeFilter.value[1]),
        include: isGroupEventsByCameraEnabled.value
          ? `${DEFAULT_EVENT_INCLUDES.toString()},${
              sortOption === '-timestamp' ? EVENT_TYPES.lastSample : EVENT_TYPES.firstSample
            }`
          : DEFAULT_EVENT_INCLUDES.toString(),
        sort: sortOption,
        pageToken: pageToken,
      };

      if (actorIDs?.length) {
        params.actor__in = actorIDs;
      }

      if (selectedFilterObject) {
        objectFilters.value = selectedFilterObject;
      }

      const payload: VSCommonRequestBodyMeta = {
        objects__all: objectFilters.value,
      };

      if (isGroupEventsByCameraEnabled.value) {
        this.getPaginatedEventsByGroup(params, payload);
      } else {
        this.getPaginatedEventsByDeepSearch({ ...params }, payload);
      }
    },

    getEventsByDisplayText(displayText: string) {
      searchText.value = '';

      if (displayText) {
        this.getAttributesForAnNLPQuery({ language: locale.getCurrentLanguage() }, { query: displayText.trim() });
      }
    },

    // Face search
    // To get face crops for video search page
    async fetchFaceCropsEventsByDeepSearch(
      params: VSEventsDeepSearchQueryParams,
      payload: VSCommonRequestBodyMeta
    ): Promise<void> {
      try {
        isLoadingFaceCropsEvents.value = true;

        const response = await APIList.fetchVSEventsByDeepSearch(params, payload);

        const results = response.results ?? [];

        faceCropEvents.value = results;
        faceCropsEventsTotalSize.value = response.totalSize ?? results.length;
        faceCropsEventsNextPageToken.value = response.nextPageToken;
        faceCropsEventsPrevPageToken.value = response.prevPageToken;
      } catch (error) {
        console.log(error);
        window.gtag('event', 'FS_Deep_Search_API_Failed');
      } finally {
        isLoadingFaceCropsEvents.value = false;
      }
    },

    async fetchPaginatedFaceCropsEventsByDeepSearch(
      params: VSEventsDeepSearchQueryParams,
      payload: VSCommonRequestBodyMeta
    ): Promise<void> {
      try {
        isLoadingFaceCropsEvents.value = true;

        const response = await APIList.fetchVSEventsByDeepSearch(params, payload);

        const results = response.results ?? [];

        faceCropEvents.value = faceCropEvents.value.concat(results);
        faceCropsEventsTotalSize.value = response.totalSize ?? results.length;
        faceCropsEventsNextPageToken.value = response.nextPageToken;
        faceCropsEventsPrevPageToken.value = response.prevPageToken;
      } catch (error) {
        console.log(error);
        window.gtag('event', 'FS_Deep_Search_API_Failed');
      } finally {
        isLoadingFaceCropsEvents.value = false;
      }
    },

    async fetchPaginatedFaceSearchEventsByDeepSearch(
      params: VSEventsDeepSearchQueryParams,
      payload: VSCommonRequestBodyMeta
    ): Promise<void> {
      try {
        isLoadingFaceSearchEvents.value = true;

        const response = await APIList.fetchVSEventsByDeepSearch(params, payload);

        const results = response.results ?? [];

        faceSearchEvents.value = faceSearchEvents.value.concat(results);
        faceSearchEventsTotalSize.value = response.totalSize ?? results.length;
        faceSearchEventsNextPageToken.value = response.nextPageToken;
        faceSearchEventsPrevPageToken.value = response.prevPageToken;
      } catch (error) {
        console.log(error);
        window.gtag('event', 'FS_Deep_Search_API_Failed');
      } finally {
        isLoadingFaceSearchEvents.value = false;
      }
    },

    async getPaginatedFaceSearchEventsByGroup(
      params: VSEventsDeepSearchQueryParams,
      payload: VSCommonRequestBodyMeta
    ): Promise<void> {
      try {
        isLoadingFaceSearchEvents.value = true;
        const response = await APIList.fetchVSEventsByGroup(params, payload);
        const results = response.results ?? [];

        faceSearchEvents.value = faceSearchEvents.value.concat(results);
        faceSearchEventsNextPageToken.value = response.nextPageToken;
        faceSearchEventsPrevPageToken.value = response.prevPageToken;
      } catch (error) {
        console.log(error);
        window.gtag('event', 'FS_Deep_Search_Group_By_Resource_API_Failed');
      } finally {
        isLoadingFaceSearchEvents.value = false;
      }
    },

    async fetchIndividualFaceSearchEvent(faceID: string, params: VSFaceSearchEventQueryParams) {
      try {
        return APIList.fetchFaceSearchEventByID(faceID, params);
      } catch (error) {
        console.log(error);
        window.gtag('event', 'FS_Individual_Event_API_Failed');
      }
    },

    async fetchAllPaginatedFaceCropsEvents(queryParams?: FSQueryParams): Promise<void> {
      const { pageToken, actorIDs, sortValue } = queryParams as FSQueryParams;

      const params: VSEventsDeepSearchQueryParams & VSEventsGroupSearchQueryParams = {
        pageSize: eventsPageSize.value,
        timestamp__gte: new Date(timeFilter.value[0]).toEENApiDateTime(),
        timestamp__lte: new Date(timeFilter.value[1]).toEENApiDateTime(),
        include: DEFAULT_EVENT_INCLUDES.toString(),
      };

      if (pageToken?.length) params.pageToken = pageToken;

      if (actorIDs?.length) params.actor__in = actorIDs;

      if (sortValue?.length)
        params.sort =
          isGroupEventsByCameraEnabled.value && sortValue === '+eventCount'
            ? (eventsDefaultSort.value as '+timestamp')
            : (sortValue as '+timestamp');

      const payload: VSCommonRequestBodyMeta = {
        objects__all: [
          [
            {
              type: 'filter.objectClassification.v1',
              class: 'face',
            },
          ],
        ],
      };

      actions.fetchPaginatedFaceCropsEventsByDeepSearch({ ...params }, payload);
    },

    async fetchAllFaceCropsEvents(queryParams?: FSQueryParams): Promise<void> {
      const { pageToken, actorIDs, sortValue } = queryParams as FSQueryParams;

      const params: VSEventsDeepSearchQueryParams & VSEventsGroupSearchQueryParams = {
        pageSize: eventsPageSize.value,
        timestamp__gte: new Date(timeFilter.value[0]).toEENApiDateTime(),
        timestamp__lte: new Date(timeFilter.value[1]).toEENApiDateTime(),
        include: DEFAULT_EVENT_INCLUDES.toString(),
      };

      if (pageToken?.length) params.pageToken = pageToken;

      if (actorIDs?.length) params.actor__in = actorIDs;

      if (sortValue?.length)
        params.sort =
          isGroupEventsByCameraEnabled.value && sortValue === '+eventCount'
            ? (eventsDefaultSort.value as '+timestamp')
            : (sortValue as '+timestamp');

      const payload: VSCommonRequestBodyMeta = {
        objects__all: [
          [
            {
              type: 'filter.objectClassification.v1',
              class: 'face',
            },
            {
              type: 'filter.objectSimilarity.v1',
              unique: true,
            },
          ],
        ],
      };

      actions.fetchFaceCropsEventsByDeepSearch({ ...params }, payload);
    },

    async fetchAllFaceSearchEventsByFaceObjectID(
      faceObjectID: string,
      actorIDs?: string,
      sortOption?: '+timestamp' | '-timestamp',
      pageToken?: string
    ): Promise<void> {
      const params: VSEventsDeepSearchQueryParams & VSEventsGroupSearchQueryParams = {
        pageSize: eventsPageSize.value,
        timestamp__gte: generateTimestamp(timeFilter.value[0]),
        timestamp__lte: generateTimestamp(timeFilter.value[1]),
        include: isGroupEventsByCameraEnabled.value
          ? `${DEFAULT_EVENT_INCLUDES.toString()},${
              sortOption === '-timestamp' ? EVENT_TYPES.lastSample : EVENT_TYPES.firstSample
            }`
          : DEFAULT_EVENT_INCLUDES.toString(),
        pageToken: pageToken,
        sort:
          isGroupEventsByCameraEnabled.value && sortOption === ('+eventCount' as '+timestamp')
            ? (eventsDefaultSort.value as '+timestamp')
            : (sortOption as '+timestamp'),
      };

      if (actorIDs?.length) params.actor__in = actorIDs;

      const payload: VSCommonRequestBodyMeta = {
        objects__all: [
          [
            {
              type: 'filter.objectSimilarity.v1',
              objectId: faceObjectID,
            },
          ],
        ],
      };

      if (isGroupEventsByCameraEnabled.value) {
        this.getPaginatedFaceSearchEventsByGroup(params, payload);
      } else {
        this.fetchPaginatedFaceSearchEventsByDeepSearch(params, payload);
      }
    },
  };

  return {
    cameraFilters,
    currentDateTime,
    elapsedTimeInSeconds,
    events,
    eventsDefaultSort,
    eventsNextPageToken,
    eventsObjectValues,
    eventsPageSize,
    eventsPrevPageToken,
    eventsSort,
    eventsTotalSize,
    faceCropEvents,
    faceCropsEventsNextPageToken,
    faceCropsEventsPrevPageToken,
    faceCropsEventsTotalSize,
    faceSearchEvents,
    faceSearchEventsNextPageToken,
    faceSearchEventsPrevPageToken,
    faceSearchEventsTotalSize,
    gridColumnCount,
    isDensityMapEnabled,
    initialRange,
    isGroupEventsByCameraEnabled,
    isLoadingEvents,
    isLoadingFaceCropsEvents,
    isLoadingFaceSearchEvents,
    isLoadingObjectClassesAndAttributes,
    presetText,
    objectClasses,
    objectClassesAndAttributes,
    objectClassTypes,
    objectFilters,
    oneDayBeforeDateTime,
    searchText,
    shouldFetchFaceCropsInitially,
    shouldShowAllFaceEvents,
    shouldShowHiddenFaceMatchFeature,
    timeFilter,
    ...actions,
    ...getters,
  };
});
