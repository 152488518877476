import { SelectType, ChipsAutocompleteItem } from '@/pages/Users/AlertManager/types';

import isEmail from 'validator/es/lib/isEmail';
import isNumeric from 'validator/es/lib/isNumeric';
import isPort from 'validator/es/lib/isPort';
import isIP from 'validator/es/lib/isIP';
import isInt from 'validator/es/lib/isInt';
import isLatLong from 'validator/es/lib/isLatLong';

import errors from '@/assets/validation/ErrorMessages.json';
import { t } from '@/plugins/i18n.ts';

interface MinMax {
  min: number;
  max: number;
}

export const rules = {
  requiredText: (value: string) => !!value?.trim() || t(errors.required),
  requiredInput: (value: any) => (value !== null && value !== undefined && value !== '') || t(errors.required),
  requiredSelect: (value: SelectType) => !!value?.value || t(errors.required),
  requiredSelectValueNotObject: (value: SelectType) => !!value || t(errors.required),
  requiredChipsAutoselect: (value: ChipsAutocompleteItem[]) => !!value.length || t(errors.required),
  isValidIP: (value: string) => (!!value && isIP(value)) || t(errors.ip),
  isValidEmail: (value: string) => (!!value && isEmail(value)) || t(errors.email),
  isNumeric: (value: string) => (value ? isNumeric(value) || t(errors.number) : true),
  isValidPortNumber: (value: string) => (!!value && isPort(value)) || t(errors.port),
  isValidMaxPerHour: (value: string) => (value ? isInt(value, { min: 1, max: 65535 }) || t(errors.maxPerHour) : true),
  isMinAndMax:
    ({ max, min }: MinMax) =>
    (value: string) =>
      value ? isInt(value, { min, max }) || t(errors.minAndMax, { min, max }) : true,
  isValidFloor: (value: string | number) =>
    value ? isInt(value.toString(), { min: 0, max: 50 }) || t(errors.floor) : true,
  isValidLatitude: (value: string) => isLatLong(value + ',0') || value === '' || t(errors.latitude),
  isValidLongitude: (value: string) => isLatLong('0,' + value) || value === '' || t(errors.longitude),
  isValidCloudRetentionDays:
    ({ max, min }: MinMax) =>
    (value: string | number) =>
      value ? isInt(value.toString(), { min, max }) || t(errors.cloudRetention, { min, max }) : true,
  isValidAzimuth: (value: number) => (value ? (value > 0 && value < 361) || t(errors.azimuth) : true),
  isValidRangeInMeters: (value: number) => (value ? value > 0 || t(errors.rangeInMeters) : true),
  isValidLength:
    ({ type, limit }: { type: string; limit: number }) =>
    (value: string) =>
      value.length <= limit || t(errors.maxTextLength, { type, limit }),
  isMaxLength: (limit: number) => (value: string | undefined) =>
    (value?.length ?? 0) <= limit || t(errors.maximumLength, { max: limit }),
  isUserNameLengthValid:
    ({ min, max }: MinMax) =>
    (value: string) =>
      (value.length >= min && value.length <= max) || t(errors.userNameLength, { min: min, max: max }),
  isValidPasswordLength:
    ({ min, max }: MinMax) =>
    (value: string) =>
      value ? (value.length >= min && value.length <= max) || t(errors.passwordLength, { min: min, max: max }) : true,
  isMinPasswordLength:
    ({ min }: { min: number }) =>
    (value: string) =>
      value ? isInt(value, { min }) || t(errors.minLength, { min: min }) : true,
  isMaxPasswordLength:
    ({ max }: { max: number }) =>
    (value: string) =>
      value ? isInt(value, { max }) || t(errors.maxLength, { max: max }) : true,
  isInvalidCharacter: (value: string) => !/[@,\\/ ]/.test(value) || t(errors.invalidCharacter),

  isStartValueAboveMaxValue:
    ({ sliderMax }: { sliderMax: number | undefined }) =>
    (value: number) => {
      if (sliderMax) return value < sliderMax;
    },
  isStartValueEqualMaxValue:
    ({ sliderMax }: { sliderMax: number | undefined }) =>
    (value: number) =>
      value !== sliderMax,

  isEndValueBelowMinValue:
    ({ sliderMin }: { sliderMin: number | undefined }) =>
    (value: number) => {
      if (sliderMin) return value > sliderMin;
    },
  isEndValueEqualMaxValue:
    ({ sliderMin }: { sliderMin: number | undefined }) =>
    (value: number) =>
      value !== sliderMin,
  isEndValueAboveMaxValue:
    ({ max }: { max: number }) =>
    (value: number) =>
      value <= max,

  isValidNumber: (value: string) => /^\d+$/.test(value),
};
