import api from '@eencloud/eewc-components/src/service/api';
import { GetLocationParams, Location, LocationGet } from '@eencloud/eewc-components/src/service/api-types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useCamerasStore } from './cameras';
import { useMessagingStore } from './messaging';
import { t } from '@/plugins/i18n.ts';

export const useLocationsStore = defineStore('locations', function () {
  const camerasStore = useCamerasStore();
  const messagingStore = useMessagingStore();
  const locations = ref<Location[]>([]);
  const location = ref<Location | null>(null);
  const loadingLocations = ref<boolean | undefined>(undefined);
  const defaultPageSize = 25;
  const nextPageToken = ref<string | undefined>();
  const prevPageToken = ref<string | undefined>();
  const pageSize = ref<number>(defaultPageSize);
  const totalSize = ref<number | undefined>(0);
  const pagedLocations = ref<Location[]>([]);
  const currentPageParams = ref();

  const getters = {
    findLocation: async (id: string) => {
      const locationData = await api.getLocation(id);
      return locationData;
    },
  };

  async function getLocations(params: GetLocationParams, locationId?: string) {
    try {
      return await api.getLocations(params, locationId);
    } catch (error) {
      console.error(error);
    }
  }

  async function getPagedLocations(params: GetLocationParams) {
    currentPageParams.value = params;
    let locationsList: LocationGet[] = [];
    try {
      const data = await getLocations(params);
      if (data) {
        nextPageToken.value = data?.nextPageToken;
        totalSize.value = data.totalSize ?? 0;
        locationsList = [...locationsList, ...(data.results as LocationGet[])];
        prevPageToken.value = data?.prevPageToken;
      }
    } catch (error) {
      console.error(error);
    }
    pagedLocations.value = locationsList;
  }

  /**
   * @param params GetLocationParams
   * @description The function will fetch all locations and save it to locations.
   */
  async function getAllLocationsByCustomParam(params: GetLocationParams) {
    let locationList: Location[] = [];
    loadingLocations.value = true;
    try {
      let pageToken: string | undefined;
      do {
        const data = await api.getLocations({
          pageToken: pageToken as string,
          pageSize: 500,
          ...params,
          include: params.include ? params.include.toString() : undefined,
        });
        if (data) {
          pageToken = data.nextPageToken;
          locationList = [...locationList, ...(data.results as Location[])];
        } else break;
      } while (pageToken !== '');
    } catch (error) {
      console.error(error);
    }
    locations.value = locationList;
    loadingLocations.value = false;
    return locationList;
  }

  async function getLocation(locationId: string) {
    try {
      location.value = null;
      const data = await api.getLocation(locationId);
      if (data) {
        location.value = data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function addLocation(payload: Location, cameraIdsToAdd: string[]) {
    const data = await api.addLocation(payload);

    if (data) {
      const locationId = data.id;
      const params: GetLocationParams = {
        include: 'notes,address,geometry,childLocationCount,effectivePermissions',
      };
      const location = await api.getLocations(params, locationId);
      locations.value = locations.value.concat(location as Location[]);

      if (cameraIdsToAdd?.length && locationId) {
        await bulkUpdateCameras(cameraIdsToAdd, locationId);
      }
      messagingStore.showSuccessMessage(
        `${t('The {locationName} location was successfully added to locations.', { locationName: data.name })}`
      );
    }
  }

  async function editLocation(
    payload: Location & {
      locationId: string;
      cameraIdsToAdd: string[];
      cameraIdsToRemove: string[];
    }
  ) {
    const { locationId, cameraIdsToAdd, cameraIdsToRemove, ...newPayload } = payload;
    const status = await api.editLocation({ location: newPayload, locationId });

    if (status) {
      if (cameraIdsToAdd?.length) {
        await bulkUpdateCameras(cameraIdsToAdd, locationId);
      }
      if (cameraIdsToRemove?.length) {
        await bulkUpdateCameras(cameraIdsToRemove, null);
      }

      const params: GetLocationParams = {
        include: 'notes,address,geometry,childLocationCount,effectivePermissions',
      };
      const editedLocation = await api.getLocations(params, locationId);
      if (editedLocation) {
        locations.value = locations.value.map((loc) => (loc.id !== locationId ? loc : (editedLocation as Location)));
      }

      messagingStore.showSuccessMessage(t('Your changes have been saved.'));
    }
  }

  async function deleteLocation(locationId: string, locationName: string): Promise<boolean> {
    try {
      const res = await api.deleteLocation(locationId);
      if (res?.status === 204) {
        locations.value = locations.value.filter((cam) => cam.id !== locationId);
        currentPageParams.value && getPagedLocations(currentPageParams.value);
        handleDeleteSuccess(locationName);
        return true;
      }
      // Handles non-exceptional status codes
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  function handleDeleteSuccess(locationName: string) {
    const bodyText = `${t('The {locationName} location was deleted.', {
      locationName: `<span class='font-weight-medium'>${locationName}</span>`,
    })}`;
    messagingStore.showSuccessMessage(bodyText);
  }

  async function bulkUpdateCameras(cameras: string[], locationId: string | null) {
    const bulkPayload = {
      ids: cameras,
      updateFields: {
        locationId,
      },
    };

    return await camerasStore.bulkUpdateCamera(bulkPayload);
  }

  /**
   * function which filters the Locations based on search keyword which matches location name
   *
   * @param searchQuery: string
   *
   * @returns the locations list which meets the search criterion
   */
  function getFilteredLocations(searchQuery: string): Location[] {
    return locations.value.filter((location) => location.name?.toLowerCase().includes(searchQuery));
  }

  return {
    locations,
    location,
    loadingLocations,
    nextPageToken,
    prevPageToken,
    pageSize,
    totalSize,
    pagedLocations,
    getAllLocationsByCustomParam,
    getLocations,
    getLocation,
    addLocation,
    editLocation,
    deleteLocation,
    getFilteredLocations,
    getPagedLocations,
    ...getters,
  };
});
