export const HOUR = 3600000;
export const DAY = 24 * HOUR;
export const MINUTE = 60000;
export const EVENTS_API_CHUNK_SIZE = DAY; // due to api limitations we can only request events for a maximum of 1 day
export const MEDIA_API_CHUNK_SIZE = 2 * DAY;
// this is fallback value in case camera settings are not available
export const DEFAULT_RETENTION_PERIOD_DAYS = 30;
// backend does not trim events and media exactly at the end of the retention period
export const RETENTION_PERIOD_MARGIN_DAYS = 1;
export const TIMELINE_ZOOM_LEVELS: Record<number, { timing: number; timeGap: number }> = {
  1: { timing: MINUTE, timeGap: 0 },
  2: { timing: 2 * MINUTE, timeGap: 1000 },
  3: { timing: 3 * MINUTE, timeGap: 2000 },
  4: { timing: 4 * MINUTE, timeGap: 3000 },
  5: { timing: 5 * MINUTE, timeGap: 4000 },
  6: { timing: 10 * MINUTE, timeGap: 7000 },
  7: { timing: 15 * MINUTE, timeGap: 10000 },
  8: { timing: 20 * MINUTE, timeGap: 15000 },
  9: { timing: 30 * MINUTE, timeGap: 20000 },
  10: { timing: 45 * MINUTE, timeGap: 25000 },
  11: { timing: HOUR, timeGap: 30000 },
  // 12: { timing: 1.25 * HOUR, timeGap: 35000 },
  // 13: { timing: 1.5 * HOUR, timeGap: 39000 },
  // 14: { timing: 1.75 * HOUR, timeGap: 43000 },
  // 15: { timing: 2.0 * HOUR, timeGap: 47000 },
  // 16: { timing: 2.25 * HOUR, timeGap: 50000 },
  // 17: { timing: 2.5 * HOUR, timeGap: 53000 },
  // 18: { timing: 2.75 * HOUR, timeGap: 57000 },
  // 19: { timing: 3 * HOUR, timeGap: 60000 },
};
