<template>
  <div>
    <eewc-settings-page
      :tab-names="tabNames"
      :active-tab="activeTab"
      class="multi-camera-settings"
      @updateActiveTab="updateActiveTab"
    >
      <template #content>
        <v-tab-item :transition="false">
          <v-card flat>
            <v-card-text>
              <multi-camera-general-settings
                ref="multiCameraGeneralSettings"
                :camera-data="multiCameraData"
                :camera-data-loading="loading || saving"
                :camera-settings-data="multiCameraSettingsData"
                :camera-settings-data-loading="loading || saving"
              >
                <template #cameraInfoBar>
                  <camera-info-bar
                    :camera-data="multiCameraData"
                    :camera-data-loading="loading || saving"
                    :camera-settings-data="multiCameraSettingsData"
                    :camera-settings-data-loading="loading || saving"
                    :bridge-data="bridgeData"
                    :bridge-data-loading="loading || saving"
                    :is-password-view-allowed="isPasswordViewAllowed"
                    @openVpnSidebar="vpnSidebarOpen = true"
                  />
                </template>
              </multi-camera-general-settings>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :transition="false">
          <v-card flat>
            <v-card-text>
              <multi-camera-view
                ref="multiCameraViews"
                :camera-id="multiCameraId"
              >
                <template #cameraInfoBar>
                  <camera-info-bar
                    :camera-data="multiCameraData"
                    :camera-data-loading="loading || saving"
                    :camera-settings-data="multiCameraSettingsData"
                    :camera-settings-data-loading="loading || saving"
                    :bridge-data="bridgeData"
                    :bridge-data-loading="loading || saving"
                    :is-password-view-allowed="isPasswordViewAllowed"
                    @openVpnSidebar="vpnSidebarOpen = true"
                  />
                </template>
              </multi-camera-view>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </template>
      <template #buttons>
        <eewc-button-common
          type="outlined"
          @click="handleCancel"
        >
          {{ t('Cancel') }}
        </eewc-button-common>
        <eewc-button-common
          :loading="saveButtonLoading"
          color="accent"
          @click="saveMultiCameraData"
        >
          {{ t('Save changes') }}
        </eewc-button-common>
      </template>
      <template #headerButtons>
        <eewc-button-common
          v-if="isDeletePermitted"
          color="negative"
          type="clear"
        >
          {{ t('Delete camera') }}
        </eewc-button-common>
      </template>
    </eewc-settings-page>
    <device-vpn-sidebar
      v-if="!!multiCameraData?.id && vpnSidebarOpen"
      :open="vpnSidebarOpen"
      :device-id="multiCameraData?.id"
      :device-type="'multiCamera'"
      :device-name="multiCameraData?.name"
      :is-password-view-allowed="isPasswordViewAllowed"
      @close="vpnSidebarOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { t } from '@/plugins/i18n.ts';

import router from '@/service/router';

import {
  useAccountStore,
  useAppStateStore,
  useBridgesStore,
  useCamerasStore,
  useMessagingStore,
  useMultiCamerasStore,
  useUsersStore,
} from '@/stores';
import { useMultiCameraSettingsStore } from '@/stores/multiCameraSettings';

import MultiCameraGeneralSettings from '@/pages/Cameras/MultiCameraGeneralSettings.vue';
import CameraInfoBar from '@/pages/Cameras/CameraInfoBar.vue';
import DeviceVpnSidebar from '@/pages/Dashboard/settings/DeviceVpnSidebar.vue';
import MultiCameraView from '@/pages/Cameras/MultiCameraView.vue';
import {
  ApiAddCamera,
  ApiBridgeWithIncludes,
  ApiCameraMain,
  ApiMultiCameraUpdate,
  ApiMultiCameraWithIncludes,
  ApiRegistrationStrategy,
  MultiCameraSettings,
  SettingsGet,
} from '@eencloud/eewc-components/src/service/api-types';
import { isEmptyObject } from '@/service/helpers';
import { ArrayValue } from '@eencloud/eewc-components/src/components/DragAndDrop/types';

const multiCamerasStore = useMultiCamerasStore();
const multiCameraSettingsStore = useMultiCameraSettingsStore();
const accountStore = useAccountStore();
const usersStore = useUsersStore();
const bridgeStore = useBridgesStore();
const appStateStore = useAppStateStore();
const messagingStore = useMessagingStore();
const camerasStore = useCamerasStore();

const activeTab = ref<number>();
const vpnSidebarOpen = ref(false);
const multiCameraId = ref(router.currentRoute.params.id);
const multiCameraData = ref<ApiMultiCameraWithIncludes>();
const multiCameraSettingsData = ref<SettingsGet>();
const bridgeData = ref<ApiBridgeWithIncludes>();
const loading = ref(false);
const saving = ref(false);
const multiCameraGeneralSettings = ref<HTMLFormElement | null>(null);
const multiCameraViews = ref<HTMLFormElement | null>(null);

export type Views = ArrayValue & { cameraId: string };

onMounted(() => {
  initMultiCameraData();
  initMultiCameraSettingsData();
});

const tabNames = [t('General'), t('Views')];

const isPasswordViewAllowed = computed(
  () => accountStore.account?.type === 'endUserAccount' && usersStore.permissions?.administrator
);

const saveButtonLoading = computed(() => saving.value || loading.value);

const isDeletePermitted = computed(() => multiCameraData.value?.effectivePermissions?.delete);

function updateActiveTab(tab: number) {
  activeTab.value = tab;
}

async function initMultiCameraData() {
  loading.value = true;
  await getMultiCameraData();
  if (multiCameraData.value?.bridgeId) {
    await getBridgeData(multiCameraData.value?.bridgeId);
  }
  setTopBarTitle();
  loading.value = false;
}

function setTopBarTitle() {
  appStateStore.changeTopBarBreadcrumbs(
    Object.assign({}, appStateStore.topBarBreadcrumbs, {
      title: multiCameraData.value?.name
        ? `${t('Multi-camera settings')} • ${multiCameraData.value.name}`
        : t('Multi-camera settings'),
    })
  );
}

async function initMultiCameraSettingsData() {
  const params = {
    include: 'schema',
  };
  multiCameraSettingsData.value = await multiCameraSettingsStore.getMultiCameraSettings(params, multiCameraId.value);
}

async function getMultiCameraData() {
  const params = {
    include: [
      'status',
      'timeZone',
      'notes',
      'tags',
      'networkInfo',
      'deviceInfo',
      'firmware',
      'effectivePermissions',
    ].join(','),
  };

  multiCameraData.value = await multiCamerasStore.getMultiCamera(params, multiCameraId.value);
}

async function getBridgeData(bridgeId: string) {
  bridgeData.value = await bridgeStore.getBridge(bridgeId);
}

function handleCancel() {
  const isGeneralDirty =
    multiCameraGeneralSettings.value?.isDirty.timeZoneDirty() ||
    multiCameraGeneralSettings.value?.isDirty.loginCredentialDirty() ||
    multiCameraGeneralSettings.value?.isDirty.cameraDataDirty();

  if (isGeneralDirty) {
    messagingStore.showWarningDialog({
      title: t('Unsaved changes'),
      callback: () => router.back(),
      doNotShowNotification: true,
      bodyText: t('If you leave this page, your changes may be lost.'),
      iconColor: 'warning',
      icon: '$icon_attention',
    });
  } else {
    router.back();
  }
}

function getMultiCameraDataPayload() {
  const multiCameraPayload: ApiMultiCameraUpdate = {};
  if (!multiCameraSettingsData.value) return multiCameraPayload;

  if (multiCameraGeneralSettings.value) {
    const { isDirty, getUpdates } = multiCameraGeneralSettings.value;

    if (isDirty.cameraDataDirty()) {
      const { name, tags, notes } = getUpdates.cameraData();
      multiCameraPayload.name = name;
      multiCameraPayload.notes = notes;
      multiCameraPayload.tags = tags;
    }
  }

  return multiCameraPayload;
}

function getMultiCameraSettingsDataPayload() {
  const multiCameraSettingsPayload: MultiCameraSettings = {};
  if (!multiCameraData.value) return multiCameraSettingsPayload;

  if (multiCameraGeneralSettings.value) {
    const { isDirty, getUpdates } = multiCameraGeneralSettings.value;

    if (isDirty.timeZoneDirty()) {
      const timeZone = getUpdates.timeZoneData();
      multiCameraSettingsPayload.timeZone = timeZone;
    }

    if (isDirty.loginCredentialDirty()) {
      const loginCredentials = getUpdates.loginCredentialData();
      multiCameraSettingsPayload.credentials = loginCredentials;
    }
  }

  return multiCameraSettingsPayload;
}

function deleteViews(views: Views[]) {
  const promises = views.map((view) => camerasStore.deleteCamera(view.cameraId));

  return promises;
}

function addViews(views: Views[]) {
  const showSuccessMessage = false;

  const promises = views.map((view) => {
    const params = {
      name: `${multiCameraData.value?.name}(${view.text})`,
      guid: view.value,
      bridgeId: bridgeData.value?.id,
      registrationStrategy: ApiRegistrationStrategy.Bridge,
    };

    return camerasStore.addCamera(params, showSuccessMessage);
  });

  return promises;
}

async function updateViews() {
  let promises: Promise<any>[] = [];

  if (multiCameraViews.value) {
    const { isDirty, getUpdates } = multiCameraViews.value;

    if (isDirty() && getUpdates.availableViews().length) {
      promises = [...promises, ...deleteViews(getUpdates.availableViews())];
    }

    if (isDirty() && getUpdates.connectedViews().length) {
      promises = [...promises, ...addViews(getUpdates.connectedViews())];
    }
  }

  return promises;
}

function validateForms() {
  if (!multiCameraGeneralSettings.value?.isValid()) {
    activeTab.value = tabNames.indexOf('General');
  } else {
    return true;
  }
  return false;
}

async function patchMultiCameraData(payload: ApiMultiCameraUpdate) {
  const result = await multiCamerasStore.patchMultiCamera(payload, multiCameraId.value);
  if (result) initMultiCameraData();

  return result;
}

async function patchMultiCameraSettingsData(payload: MultiCameraSettings) {
  const result = await multiCameraSettingsStore.updateMultiCameraSettings(payload, multiCameraId.value);
  if (result) initMultiCameraSettingsData();

  return result;
}

async function getMultiCameraPayloadPromises() {
  const promises = [];
  const multiCameraDataPayload = getMultiCameraDataPayload();
  const multiCameraSettingsDataPayload = getMultiCameraSettingsDataPayload();

  if (!isEmptyObject(multiCameraDataPayload)) {
    promises.push(patchMultiCameraData(multiCameraDataPayload));
  }

  if (!isEmptyObject(multiCameraSettingsDataPayload)) {
    promises.push(patchMultiCameraSettingsData(multiCameraSettingsDataPayload));
  }

  return promises;
}

async function saveMultiCameraData() {
  if (!validateForms()) return;

  // Delaying the save function will resolve the issue of missing created tags in the payload.
  setTimeout(async () => {
    const multiCameraSettingsPromises = await getMultiCameraPayloadPromises();
    const multiCameraChannelsPromises = await updateViews();

    if (!multiCameraSettingsPromises.length && !multiCameraChannelsPromises?.length) return;

    saving.value = true;

    try {
      const results = await Promise.allSettled([...multiCameraSettingsPromises, ...multiCameraChannelsPromises]);
      const isAllResolved = results.length && results.every((result) => result.status === 'fulfilled');

      if (isAllResolved) showNotification();
    } catch (error) {
      console.error(error);
    } finally {
      saving.value = false;
    }
  }, 100);
}

function showNotification() {
  messagingStore.addNotification({
    iconColor: 'positive',
    icon: '$icon_check_zero',
    title: t('Camera updated'),
    body: t('{deviceName} has been successfully updated', {
      deviceName: multiCameraData.value?.name ?? 'Camera',
    }),
  });
}
</script>
