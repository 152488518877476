import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useUsersStore } from './users';

export const useWebSocketStore = defineStore('webSocket', () => {
  type CameraEvent = {
    type: string; // type refers to the UX menu from where the cameras are subscribed
    cameraIds: string[];
  };

  const usersStore = useUsersStore();

  /**
   * @description This array stores the camera events that are subscribed to.
   * Each element in the array is an object with a type and cameraIds property.
   */
  const subscribedCameras = ref<CameraEvent[]>([]);
  const ws = ref<WebSocket | null>(null);

  const CAMERA_EVENT_TOPIC = {
    resource: ['event', 'status'],
    event: ['PTZS', 'CZTS'], // removed 'ANNT' (annotation) as it is not required for now
  };

  const ACCOUNT_EVENT_TOPIC = {
    resource: ['event'],
    event: ['AEEC', 'AEED', 'AEEL'],
  };

  function setWebSocketClient(wsClient: WebSocket) {
    ws.value = wsClient;
  }

  function unSubscribeCameras(type: string, cameraIds?: string[]) {
    if (!cameraIds) {
      subscribedCameras.value = subscribedCameras.value.filter((cam) => cam.type !== type);
    }
    const camera = subscribedCameras.value.find((cam) => cam.type === type);
    if (camera) {
      camera.cameraIds = camera.cameraIds.filter((id) => !cameraIds.includes(id));
    }
    sendSubscriptionData();
  }

  function subscribeCameras(type: string, cameraIds: string[]) {
    const subscribedCamera = subscribedCameras.value.find((cam) => cam.type === type);
    if (subscribedCamera) {
      subscribedCamera.cameraIds.push(...cameraIds);
    } else {
      subscribedCameras.value.push({ type, cameraIds });
    }
    sendSubscriptionData();
  }

  function sendSubscriptionData() {
    const uniqueIds = Array.from(new Set(subscribedCameras.value.flatMap((cam) => cam.cameraIds)));
    const resource = uniqueIds.reduce((acc, id) => {
      acc[id] = { ...CAMERA_EVENT_TOPIC };
      return acc;
    }, {});
    if (usersStore.currentUser?.accountId) {
      resource[usersStore.currentUser.accountId] = ACCOUNT_EVENT_TOPIC;
    }
    ws.value && ws.value.send(JSON.stringify({ cameras: resource }));
  }

  return { setWebSocketClient, subscribeCameras, unSubscribeCameras };
});
