import { Ref, computed, reactive } from 'vue';
import router from '@/service/router';
import { t } from '@/plugins/i18n';
import { useAppStateStore } from '@/stores';

function useSensorsNavigation(sensorsActiveRoute: Ref<string>) {
  const appState = useAppStateStore();
  const showHiddenFeaturesRef = computed(() => appState.showHiddenFeatures);

  function routeActive(routeName: string) {
    return sensorsActiveRoute.value === routeName;
  }

  function sensorSubMenuItems() {
    return [
      {
        id: 'devices',
        name: t('Devices'),
        active:
          sensorsActiveRoute.value === 'Sensors' ||
          sensorsActiveRoute.value === 'Add gateway' ||
          sensorsActiveRoute.value === 'Gateway settings' ||
          sensorsActiveRoute.value === 'Add sensor device' ||
          sensorsActiveRoute.value === 'Available sensor devices' ||
          sensorsActiveRoute.value === 'Sensor device settings',
      },
      {
        id: 'values',
        name: t('Values'),
        active: ['Values', 'Sensor settings'].includes(sensorsActiveRoute.value),
      },
      {
        id: 'history',
        name: t('History'),
        active: sensorsActiveRoute.value === 'History',
      },
    ];
  }

  const sensorsNavigationData = reactive({
    items: computed(() => {
      return showHiddenFeaturesRef.value
        ? {
            icon: '$icon_sensor',
            name: t('Sensors'),
            active:
              routeActive('Sensors') ||
              routeActive('Add gateway') ||
              routeActive('Values') ||
              routeActive('History') ||
              routeActive('Gateway settings') ||
              routeActive('Add sensor device') ||
              routeActive('Available sensor devices') ||
              routeActive('Sensor device settings') ||
              routeActive('Sensor settings'),
            subItems: sensorSubMenuItems(),
            route: 'Sensors',
          }
        : null;
    }),
  });

  const handleSensorsChildrenRoutes = (id: string): boolean => {
    let targetRoute = '';

    switch (id) {
      case 'devices':
        targetRoute = '/sensors/devices';
        break;
      case 'values':
        targetRoute = '/sensors/values';
        break;
      case 'history':
        targetRoute = '/sensors/history';
        break;
      default:
        return false;
    }

    if (router.currentRoute.path !== targetRoute) {
      router.push(targetRoute);
    }

    return true;
  };

  return {
    sensorsNavigationData,
    handleSensorsChildrenRoutes,
  };
}

export default useSensorsNavigation;
