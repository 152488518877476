<template>
  <div class="camera-info">
    <div class="camera-info__title">
      {{ t('Device info') }}
    </div>
    <div
      v-if="!isAnalogCamera"
      class="info__item"
    >
      <div class="info-item__title">
        {{ t('Manufacturer') }}
      </div>
      <div class="info-item__value">
        <eewc-skeleton
          v-if="cameraDataLoading"
          type="text"
        />
        <template v-else>
          {{ cameraData?.deviceInfo?.make }}
        </template>
      </div>
    </div>
    <div
      v-if="!isAnalogCamera"
      class="info__item"
    >
      <div class="info-item__title">
        {{ t('Model') }}
      </div>
      <div class="info-item__value">
        <eewc-skeleton
          v-if="cameraDataLoading"
          type="text"
        />
        <template v-else>
          {{ cameraData?.deviceInfo?.model }}
        </template>
      </div>
    </div>
    <div
      v-if="!isAnalogCamera"
      class="info__item"
    >
      <div class="info-item__title">
        {{ t('Firmware') }}
      </div>
      <div class="info-item__value">
        <eewc-skeleton
          v-if="cameraDataLoading"
          type="text"
        />
        <template v-else>
          {{ cameraData?.firmware?.currentVersion }}
        </template>
      </div>
    </div>
    <div
      v-if="!isAnalogCamera"
      class="info__item"
    >
      <div class="info-item__title">
        {{ t('MAC address') }}
      </div>
      <div class="info-item__value">
        <eewc-skeleton
          v-if="cameraDataLoading"
          type="text"
        />
        <template v-else>
          <span v-if="cameraData?.networkInfo?.adapters && cameraData?.networkInfo?.adapters[0]?.macAddress">
            {{ cameraData?.networkInfo?.adapters[0]?.macAddress }}
          </span>
          <span v-else> - </span>
        </template>
      </div>
    </div>
    <div
      v-if="!isAnalogCamera"
      class="info__item"
    >
      <div class="info-item__title">
        {{ t('IP address') }}
      </div>
      <div class="info-item__value">
        <eewc-skeleton
          v-if="cameraDataLoading"
          type="text"
        />
        <template v-else>
          <span
            v-if="cameraData?.networkInfo?.adapters && cameraData?.networkInfo?.adapters[0]?.ipAddress"
            :class="[isDirectCloudCamera ? 'info-item__link--disabled' : 'info-item__link']"
            @click="openVpnSidebar"
          >
            {{ cameraData?.networkInfo?.adapters[0]?.ipAddress }}
          </span>
          <span v-else> - </span>
        </template>
      </div>
    </div>
    <div v-if="isAnalogCamera">
      <div class="info-item">
        <div class="info-item__title">
          {{ t('Analog input') }}
        </div>
        <div class="info-item__value">
          <eewc-skeleton
            v-if="cameraDataLoading"
            type="text"
          />
          <template v-else>
            <span>{{ cameraData?.analog?.input ?? '-' }}</span>
          </template>
        </div>
      </div>
    </div>
    <div class="info-item">
      <div class="info-item__title">
        {{ t('ESN') }}
      </div>
      <div class="info-item__value">
        <eewc-skeleton
          v-if="cameraDataLoading"
          type="text"
        />
        <template v-else>
          {{ cameraData?.id }}
        </template>
      </div>
    </div>
    <div
      v-if="bridgeData?.name"
      class="info__item"
    >
      <div class="info-item__title">
        {{ t('Bridge') }}
      </div>
      <div class="info-item__value">
        <eewc-skeleton
          v-if="bridgeDataLoading"
          type="text"
        />
        <template v-else>
          <span
            v-if="bridgeData?.name"
            :class="[
              isDirectCloudCamera && addEditBridgesCamerasPermissions ? 'info-item__link--disabled' : 'info-item__link',
            ]"
            @click="openBridgeSettings"
          >
            {{ bridgeData?.name }} ( {{ t('ESN') + ':' }} {{ bridgeData?.id }})
          </span>
          <span v-else> - </span>
        </template>
      </div>
    </div>
    <div
      v-if="cameraData?.adminCredentials?.username"
      class="info__item"
    >
      <div class="info-item__title">
        {{ t('Default username') }}
      </div>
      <div class="info-item__value">
        <eewc-skeleton
          v-if="cameraDataLoading"
          type="text"
        />
        <template v-else>
          <span>{{ cameraData?.adminCredentials?.username ?? '-' }}</span>
        </template>
      </div>
    </div>
    <div
      v-if="cameraData?.adminCredentials?.password"
      class="info__item"
    >
      <div class="info-item__title">
        {{ t('Default password') }}
      </div>
      <div class="info-item__value">
        <eewc-skeleton
          v-if="cameraDataLoading"
          type="text"
        />
        <template v-else>
          <div
            v-if="cameraData?.adminCredentials"
            class="d-flex justify-space-between"
          >
            <span class="body-2">{{
              showDefaultPassword ? cameraData.adminCredentials.password : hashedPassword
            }}</span>
            <v-icon
              v-if="isPasswordViewAllowed"
              right
              @click.prevent="showDefaultPassword = !showDefaultPassword"
            >
              {{ showDefaultPassword ? '$icon_no_permissions' : '$icon_eye' }}
            </v-icon>
          </div>
          <span v-else> - </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  ApiBridgeWithIncludes,
  ApiCameraSettings,
  ApiCameraWithIncludes,
} from '@eencloud/eewc-components/src/service/api-types';
import { t } from '@/plugins/i18n.ts';
import router from '@/service/router';
import { useUsersStore } from '@/stores';

const props = defineProps<{
  cameraData?: ApiCameraWithIncludes;
  cameraDataLoading: boolean;
  cameraSettingsData?: ApiCameraSettings;
  cameraSettingsDataLoading: boolean;
  bridgeData?: ApiBridgeWithIncludes;
  bridgeDataLoading: boolean;
  isPasswordViewAllowed?: boolean;
}>();

const emit = defineEmits<{
  (e: 'openVpnSidebar'): void;
}>();

const userStore = useUsersStore();

const showDefaultPassword = ref<boolean>(false);

const addEditBridgesCamerasPermissions = computed(() => userStore.permissions?.addEditBridgesCameras);
const isAnalogCamera = computed(() => !!props.cameraSettingsData?.analog);
const isDirectCloudCamera = computed(() => props.cameraData?.bridgeId === null);
const hashedPassword = computed(() => '•'.repeat(props.cameraData?.adminCredentials?.password?.length));

function openVpnSidebar() {
  emit('openVpnSidebar');
}

function openBridgeSettings() {
  props.bridgeData?.id &&
    addEditBridgesCamerasPermissions.value &&
    router.push({ name: 'Bridge settings', params: { id: props.bridgeData?.id } });
}
</script>

<style lang="scss" scoped>
@import '@/styles/public/main.scss';
.camera-info {
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  background-color: $backgrounds;
  border: 1px solid $elements;
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 20px;
  &__title {
    @include subtitle-2;
    color: $primary;
  }
}

.info-item {
  &__title {
    @include counter;
    color: $secondary;
  }
  &__value {
    @include body-2;
    color: $primary;
  }
  &__link {
    color: $accent;
    cursor: pointer;
    text-decoration: underline;
    &--disabled {
      color: $primary;
      cursor: none;
      text-decoration: none;
      pointer-events: none;
    }
  }
}
</style>
