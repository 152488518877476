import { Route } from '@/types/index';

const VSPAddRule = () => import('@/pages/VSP/rules/AddRule.vue');
const VSPAddVehicle = () => import('@/pages/VSP/vehicleList/AddVehicle.vue');
const VSPAlerts = () => import('@/pages/VSP/alerts/Alerts.vue');
const VSPEditRule = () => import('@/pages/VSP/rules/EditRule.vue');
const VSPEditVehicle = () => import('@/pages/VSP/vehicleList/EditVehicle.vue');
const VSPRules = () => import('@/pages/VSP/rules/Rules.vue');
const VSPSearch = () => import('@/pages/VSP/search/Search.vue');
const VSPSingleVehicleList = () => import('@/pages/VSP/vehicleList/SingleVehicleList.vue');
const VSPSummary = () => import('@/pages/VSP/summary/VSPSummary.vue');
const VSPVehicleCSVUpload = () => import('@/pages/VSP/vehicleList/VehicleCSVUpload.vue');
const VSPVehicleList = () => import('@/pages/VSP/vehicleList/VehicleList.vue');
const VSPMap = () => import('@/pages/VSP/search/maps/Map.vue');
const VSPReports = () => import('@/pages/VSP/reports/Reports.vue');

const routes: Route[] = [
  {
    path: '/vsp/summary',
    name: 'Summary',
    component: VSPSummary,
    meta: {
      auth: true,
      permissions: ['viewVSP'],
      rootRoute: true,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/search',
    name: 'Search',
    component: VSPSearch,
    meta: {
      auth: true,
      permissions: ['viewVSP'],
      rootRoute: true,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/vehicleList',
    name: 'Vehicle list',
    component: VSPVehicleList,
    meta: {
      auth: true,
      permissions: ['viewVehicleList'],
      rootRoute: true,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/rules',
    name: 'Rules',
    component: VSPRules,
    meta: {
      auth: true,
      permissions: ['viewVSPRule'],
      rootRoute: true,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/alerts',
    name: 'Alerts',
    component: VSPAlerts,
    meta: {
      auth: true,
      permissions: ['viewVSP'],
      rootRoute: true,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/vehicleList/:id/add-vehicle',
    name: 'Add vehicle',
    component: VSPAddVehicle,
    meta: {
      auth: true,
      rootRoute: false,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/vehicleList/add-vehicle',
    name: 'Add vehicle',
    component: VSPAddVehicle,
    meta: {
      auth: true,
      rootRoute: false,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/vehicleList/:id/:recordId/edit-vehicle',
    name: 'Edit vehicle',
    component: VSPEditVehicle,
    meta: {
      auth: true,
      rootRoute: false,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/vehicleList/:id',
    name: 'Single vehicle list',
    component: VSPSingleVehicleList,
    meta: {
      auth: true,
      rootRoute: true,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/vehicleList/:id/csv-upload',
    name: 'Upload list via CSV',
    component: VSPVehicleCSVUpload,
    meta: {
      auth: true,
      rootRoute: false,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/rules/add-rule',
    name: 'Add rule',
    component: VSPAddRule,
    meta: {
      auth: true,
      rootRoute: false,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/rules/:id/edit-rule',
    name: 'Edit rule',
    component: VSPEditRule,
    meta: {
      auth: true,
      rootRoute: false,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/search/maps',
    name: 'License plate search',
    component: VSPMap,
    meta: {
      auth: true,
      rootRoute: false,
      vspSubRoute: true,
    },
  },
  {
    path: '/vsp/reports',
    name: 'VSP Reports',
    component: VSPReports,
    meta: {
      auth: true,
      rootRoute: false,
      vspSubRoute: true,
    },
  },
];

export default routes;
