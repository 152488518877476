import type {
  TimeRangeObject,
  CameraMediaStore,
  VisibleCamerasFunctions,
} from '@/pages/HistoryBrowser/historyBrowserTypes';
import type {
  Event,
  ApiMediaWithIncludes,
  ApiPaginatedMediaResponse,
  mediaMainRequest,
  CameraSettingsResponse,
} from '@eencloud/eewc-components/src/service/api-types';
import { Ref } from 'vue';
import {
  DAY,
  DEFAULT_RETENTION_PERIOD_DAYS,
  MEDIA_API_CHUNK_SIZE,
  RETENTION_PERIOD_MARGIN_DAYS,
} from '@/pages/HistoryBrowser/constants';

export function getVideoElement(cameraId: string): HTMLVideoElement {
  return document.getElementById('videoElement' + cameraId) as HTMLVideoElement;
}

/**
 * Calculates the time range based on the selected time, fetch chunk size, and current date.
 * @param selectedTime - The selected time in milliseconds.
 * @param fetchChunkSize - The size of the fetch chunk in milliseconds.
 * @param dateNow - The current date in milliseconds.
 * @returns An object containing the start and end time of the calculated range.
 */
export function calculateTimeRange(selectedTime: number, fetchChunkSize: number, dateNow: number): TimeRangeObject {
  let newStartTime = selectedTime - fetchChunkSize / 2;
  let newEndTime = selectedTime + fetchChunkSize / 2;

  // if the newEndTime is in the future we need to reduce it
  if (newEndTime > dateNow) {
    newEndTime = dateNow;
    newStartTime = newEndTime - fetchChunkSize;
  }
  return {
    start: newStartTime,
    end: newEndTime,
  };
}

/**
 * Calculates the overlap between two time ranges and return an object containing boolean values indicating the overlap.
 * @param newTimeLine The new time range to compare.
 * @param currentTimeLine The current time range to compare against.
 * @returns An object containing boolean values indicating the overlap between the time ranges.
 */
export function calculateTimeRangeOverlap(
  newTimeLine: TimeRangeObject,
  currentTimeLine: TimeRangeObject
): {
  isNewTimeLineEarlier: boolean;
  isNewTimeLineLater: boolean;
  isNewTimeLineOutside: boolean;
  isNewTimeLineInMiddle: boolean;
  isNewTimeLineCoversTheWholeRange: boolean;
} {
  const isNewTimeLineInMiddle = newTimeLine.start >= currentTimeLine.start && newTimeLine.end <= currentTimeLine.end;
  const isNewTimeLineEarlier = !!(
    currentTimeLine.start &&
    currentTimeLine.start > newTimeLine.start &&
    currentTimeLine.end >= newTimeLine.end &&
    currentTimeLine.start <= newTimeLine.end
  );
  const isNewTimeLineLater = !!(
    currentTimeLine.end &&
    newTimeLine.end > currentTimeLine.end &&
    newTimeLine.start >= currentTimeLine.start &&
    newTimeLine.start <= currentTimeLine.end
  );
  const isNewTimeLineOutside = !isNewTimeLineEarlier && !isNewTimeLineLater && !isNewTimeLineInMiddle;
  const isNewTimeLineCoversTheWholeRange =
    newTimeLine.start <= currentTimeLine.start && newTimeLine.end >= currentTimeLine.end;
  return {
    isNewTimeLineEarlier,
    isNewTimeLineLater,
    isNewTimeLineOutside,
    isNewTimeLineInMiddle,
    isNewTimeLineCoversTheWholeRange,
  };
}

/**
 * Checks if a given time range is valid based on the maximum allowed range.
 * @param timeRange - The time range object to validate.
 * @param maxAllowedRange - The maximum allowed range in hours.
 * @returns A boolean indicating whether the time range is valid or not.
 */
export function isTimeRangeValid(timeRange: TimeRangeObject, maxAllowedRange: number): boolean {
  // invalid range
  if (timeRange.start > 0 && timeRange.end > 0 && timeRange.start > timeRange.end) return false;
  // time range bigger than max allowed hours range
  if (maxAllowedRange && timeRange.end - timeRange.start > maxAllowedRange) return false;
  return true;
}

/**
 * Checks if the expected coverage is satisfied by the current coverage.
 * @param expectedCoverage The expected coverage range.
 * @param currentCoverage The current coverage range.
 * @returns True if the expected coverage is satisfied, false otherwise.
 */
export function checkIfCoverageSatisfied(expectedCoverage: TimeRangeObject, currentCoverage: TimeRangeObject): boolean {
  return expectedCoverage.start >= currentCoverage.start && expectedCoverage.end <= currentCoverage.end;
}

/**
 * Calculates the new selected time based on the expected coverage, current coverage, and fetch chunk size.
 * If the expected coverage starts before the current coverage, it returns the current coverage start minus half of the fetch chunk size.
 * Otherwise, it returns the current coverage end plus half of the fetch chunk size.
 * @param expectedCoverage - The expected coverage range.
 * @param currentCoverage - The current coverage range.
 * @param fetchChunkSize - The size of the fetch chunk.
 * @returns The new selected time.
 */
export function getNewSelectedTime(
  expectedCoverage: TimeRangeObject,
  currentCoverage: TimeRangeObject,
  fetchChunkSize: number,
  dateNow: number
): number {
  let newTime = 0;
  if (expectedCoverage.start < currentCoverage.start) {
    newTime = currentCoverage.start - fetchChunkSize / 2;
  } else {
    newTime = currentCoverage.end + fetchChunkSize / 2;
  }
  if (newTime > dateNow) {
    newTime = dateNow;
  }
  return newTime;
}

/**
 * Removes events outside of the specified coverage from an array of events or media.
 * @param arrayList - The array of events or media.
 * @param expectedCoverage - The expected coverage as a TimeRangeObject.
 * @returns The filtered array of events or media without events outside of the coverage.
 */
export function removeEventsOutsideOfCoverage(
  arrayList: Event[] | ApiMediaWithIncludes[],
  expectedCoverage: TimeRangeObject
): Event[] | ApiMediaWithIncludes[] {
  return arrayList.filter((event) => {
    const eventStart = new Date(event.startTimestamp).getTime();
    const eventEnd = new Date(event.endTimestamp).getTime();
    const { isNewTimeLineOutside } = calculateTimeRangeOverlap({ start: eventStart, end: eventEnd }, expectedCoverage);
    return !isNewTimeLineOutside;
  });
}

/**
 * Shrinks the time range of the current coverage based on the expected coverage.
 * @param currentCoverage The current time range coverage.
 * @param expectedCoverage The expected time range coverage.
 * @returns The updated time range object with the shrunk coverage.
 */
export function shrinkTimeRange(currentCoverage: TimeRangeObject, expectedCoverage: TimeRangeObject): TimeRangeObject {
  const newTimeLine = currentCoverage;
  const currentTimeLine = expectedCoverage;
  const { isNewTimeLineEarlier, isNewTimeLineLater, isNewTimeLineCoversTheWholeRange } = calculateTimeRangeOverlap(
    newTimeLine,
    currentTimeLine
  );
  let { start, end } = currentCoverage;
  if (isNewTimeLineEarlier) {
    start = expectedCoverage.start;
  } else if (isNewTimeLineLater) {
    end = expectedCoverage.end;
  } else if (isNewTimeLineCoversTheWholeRange) {
    start = expectedCoverage.start;
    end = expectedCoverage.end;
  } else {
    start = currentCoverage.start;
    end = currentCoverage.end;
  }
  return { start, end };
}

/**
 * Expands the time range based on the current coverage and a new time line.
 * @param currentCoverage The current time range coverage.
 * @param newTimeLine The new time line to expand the coverage with.
 * @returns The expanded time range.
 */
export function expandTimeRange(currentCoverage: TimeRangeObject, newTimeLine: TimeRangeObject): TimeRangeObject {
  const { isNewTimeLineEarlier, isNewTimeLineLater, isNewTimeLineInMiddle } = calculateTimeRangeOverlap(
    newTimeLine,
    currentCoverage
  );
  let { start, end } = currentCoverage;
  if (isNewTimeLineEarlier) {
    start = newTimeLine.start;
  } else if (isNewTimeLineLater) {
    end = newTimeLine.end;
  } else if (isNewTimeLineInMiddle) {
    start = currentCoverage.start;
    end = currentCoverage.end;
  } else {
    start = newTimeLine.start;
    end = newTimeLine.end;
  }
  return { start, end };
}

/**
 * This function takes a range of time and fetches the elements for that range in chunks.
 * It also updates the store with the fetched elements and the current coverage.
 *
 * @param cameraId - The ID of the camera.
 * @param cameraMediaStore - The store for camera media data.
 * @param fetchFunction - The function to fetch the elements.
 * @param dateNow - The current date in milliseconds.
 * @param generateTimestamp - The function to generate a timestamp.
 * @param fetchChunkSize - The size of each fetch chunk.
 * @param visibleCamerasFunctions - Functions related to visible cameras.
 * @param callType - The type of elements to fetch ('events' or 'medias').
 * @param updatePendingRecordings - A reference to a boolean indicating if there are pending recordings to update.
 */
export async function getElementsForDesiredTimeRange(
  cameraId: string,
  cameraMediaStore: CameraMediaStore,
  fetchFunction: (params: unknown) => Promise<ApiMediaWithIncludes[] | Event[]>,
  dateNow: number,
  generateTimestamp: (params: string | number) => string,
  fetchChunkSize: number,
  visibleCamerasFunctions: VisibleCamerasFunctions,
  callType: 'events' | 'medias' | 'previewMedia',
  updatePendingRecordings: Ref<boolean> | undefined = undefined,
  terminateFetchLoop: Ref<boolean> | undefined = undefined
) {
  let fetching: keyof CameraMediaStore = 'fetchingEvents';
  let inRangeLoaded: keyof CameraMediaStore = 'allEventsInRangeLoaded';
  let currentCoverage: keyof CameraMediaStore = 'eventsCurrentCoverage';
  let functionName = 'getEvents';
  let elements: keyof CameraMediaStore = 'events';
  let apiCallParams = {
    deviceId: cameraId,
  };
  if (callType === 'medias') {
    fetching = 'fetchingMainMedia';
    inRangeLoaded = 'allMediaInRangeLoaded';
    currentCoverage = 'mainMediaCurrentCoverage';
    functionName = 'getMedia';
    elements = 'mediaMain';
    apiCallParams = {
      ...apiCallParams,
      coalesce: false,
      updatePendingRecordings,
      include: 'mp4Url',
    } as {
      deviceId: string;
      coalesce: boolean;
      updatePendingRecordings: Ref<boolean>;
      include: string;
    };
  }

  if (callType === 'previewMedia') {
    fetching = 'fetchingPreviewMedia';
    inRangeLoaded = 'allPreviewMediaInRangeLoaded';
    currentCoverage = 'previewMediaCurrentCoverage';
    functionName = 'getPreviewMedia';
    elements = 'mediaPreview';
    apiCallParams = {
      ...apiCallParams,
      coalesce: true,
      updatePendingRecordings,
    } as {
      deviceId: string;
      coalesce: boolean;
      updatePendingRecordings: Ref<boolean>;
    };
  }

  // this is needed to avoid infitinte loops
  // this represent max consequtive calls not max total calls
  const MAX_CALLS = 40;
  // this is needed to avoid unecessary calls
  const MAX_CALLS_ON_ERROR = 5;
  let callErrorCounter = 0;
  let isCoverageSatisfied = checkIfCoverageSatisfied(
    cameraMediaStore.expectedCoverage,
    cameraMediaStore[currentCoverage]
  );

  cameraMediaStore[fetching] = true;
  cameraMediaStore[inRangeLoaded] = false;
  if (visibleCamerasFunctions?.removeFromLoadingElements) visibleCamerasFunctions.removeFromLoadingElements(cameraId);

  let counter = 0; // counter to prevent infinite loop
  while (!isCoverageSatisfied && !terminateFetchLoop?.value) {
    const isItInitialCall =
      counter < 1 && !cameraMediaStore[currentCoverage].start && !cameraMediaStore[currentCoverage].end;

    let newTime = cameraMediaStore.currentTime;

    counter++;
    if (counter > MAX_CALLS) {
      console.warn(`infinite loop detected ${functionName}`);
      break;
    }
    if (callErrorCounter >= MAX_CALLS_ON_ERROR) {
      console.warn(`too many errors detected ${functionName}`);
      break;
    }

    if (
      !isItInitialCall &&
      !calculateTimeRangeOverlap(cameraMediaStore[currentCoverage], cameraMediaStore.expectedCoverage)
        .isNewTimeLineOutside
    ) {
      newTime = getNewSelectedTime(
        cameraMediaStore.expectedCoverage,
        cameraMediaStore[currentCoverage],
        fetchChunkSize,
        dateNow
      );
    }

    const { start, end } = calculateTimeRange(newTime, fetchChunkSize, dateNow);
    const { isNewTimeLineEarlier, isNewTimeLineLater, isNewTimeLineInMiddle } = calculateTimeRangeOverlap(
      { start, end },
      cameraMediaStore[currentCoverage]
    );

    let startTimeEpoch = start;
    let endTimeEpoch = end;

    if (isNewTimeLineEarlier) {
      // merge new time range to left of the current coverage
      endTimeEpoch = cameraMediaStore[currentCoverage].start;
    } else if (isNewTimeLineLater) {
      // merge new time range to right of the current coverage
      startTimeEpoch = cameraMediaStore[currentCoverage].end;
    } else if (isNewTimeLineInMiddle) {
      isCoverageSatisfied = true;
      continue;
    }

    if (endTimeEpoch > dateNow) {
      endTimeEpoch = dateNow;
    }

    const response = await fetchFunction({
      ...apiCallParams,
      startTimestamp: generateTimestamp(startTimeEpoch),
      endTimestamp: generateTimestamp(endTimeEpoch),
    });

    if (response) {
      callErrorCounter = 0;
      cameraMediaStore[currentCoverage] = expandTimeRange(cameraMediaStore[currentCoverage], {
        start: startTimeEpoch,
        end: endTimeEpoch,
      });
    } else {
      callErrorCounter++;
    }
    if (
      calculateTimeRangeOverlap(cameraMediaStore[currentCoverage], cameraMediaStore.expectedCoverage)
        .isNewTimeLineOutside
    ) {
      const filteredCoverage = shrinkTimeRange(cameraMediaStore[currentCoverage], cameraMediaStore.expectedCoverage);
      const elementsInTheStore = structuredClone(cameraMediaStore[elements]);
      const filteredElements = removeEventsOutsideOfCoverage(elementsInTheStore, filteredCoverage);
      cameraMediaStore[elements] = [...filteredElements];
      cameraMediaStore[currentCoverage] = filteredCoverage;
    }

    const cameraHasElements = cameraMediaStore[elements].length > 0;
    if (cameraHasElements && visibleCamerasFunctions) {
      if (!visibleCamerasFunctions.isIncludeVisibleCameras(cameraId)) {
        visibleCamerasFunctions.addToVisibleCameras(cameraId);
      }
    } else {
      visibleCamerasFunctions && visibleCamerasFunctions.removeFromVisibleCameras(cameraId);
    }
    isCoverageSatisfied = checkIfCoverageSatisfied(
      cameraMediaStore.expectedCoverage,
      cameraMediaStore[currentCoverage]
    );
  }

  if (visibleCamerasFunctions && visibleCamerasFunctions.addToLoadingElements)
    visibleCamerasFunctions.addToLoadingElements(cameraId);
  cameraMediaStore[inRangeLoaded] = true;
  cameraMediaStore[fetching] = false;
}

/**
 * Fetches a recording chunk from the media API.
 * @param params - The parameters for fetching the recording chunk.
 * @param params.cameraId - The ID of the camera to fetch the recording for.
 * @param params.currentTime - The current time in milliseconds.
 * @param params.dateNow - The current date in milliseconds.
 * @param params.generateTimestamp - The function to generate a timestamp.
 * @param params.fetchMedia - The function to fetch media.
 * @returns The first future recording chunk, or undefined if no future recordings are found.
 */
export async function fetchRecordingChunk(params: {
  cameraId: string;
  currentTime: number;
  dateNow: number;
  generateTimestamp: (params: string | number) => string;
  fetchMedia: (params: mediaMainRequest) => Promise<ApiPaginatedMediaResponse | undefined>;
}) {
  const { cameraId, currentTime, dateNow, generateTimestamp, fetchMedia } = params;
  // We are looking for future events. Hence, the timeRangeEndLimit is set to the current time of the computer.
  const timeRangeEndLimit = dateNow;

  const { end: endTimeEpoch } = calculateTimeRange(currentTime, MEDIA_API_CHUNK_SIZE, timeRangeEndLimit);

  const newStartTimeEpoch = currentTime;
  let newEndTimeEpoch = Math.min(endTimeEpoch, dateNow);
  const difference = newEndTimeEpoch - newStartTimeEpoch;
  const shortfall = MEDIA_API_CHUNK_SIZE - difference;
  if (shortfall > 0) {
    newEndTimeEpoch = newEndTimeEpoch + shortfall;
  }

  if (newEndTimeEpoch > dateNow) {
    newEndTimeEpoch = dateNow;
  }

  const response = await fetchMedia({
    deviceId: cameraId,
    startTimestamp__gte: generateTimestamp(newStartTimeEpoch),
    endTimestamp__lte: generateTimestamp(newEndTimeEpoch),
    pageSize: 2,
    type: 'main',
    mediaType: 'video',
    include: 'mp4Url',
    coalesce: true,
  });

  if (response?.results?.length) {
    // due to API bug it also returns current recording
    // we need to filter it out
    // when api bug fix (EEPD-49090) remove this filter, only request pagesize:1 and use the first element
    const futureRecordings = response.results.filter(
      (recording: ApiMediaWithIncludes) => new Date(recording.startTimestamp).getTime() > currentTime
    );
    return futureRecordings[0];
  }
  return undefined;
}

/**
 * Fetches recordings until a recording is found or the retention period is reached.
 * @param params - The parameters for fetching recordings.
 * @param params.cameraId - The ID of the camera to fetch recordings for.
 * @param params.currentTime - The current time in milliseconds.
 * @param params.retentionPeriodEpoch - The retention period in milliseconds.
 * @param params.mediaStore - The store for camera media data.
 * @param params.fetchMedia - The function to fetch media.
 * @param params.generateTimestamp - The function to generate a timestamp.
 * @returns The closest recording found, or undefined if no recording is found.
 */
export async function fetchUntilRecordingFound(params: {
  cameraId: string;
  currentTime: number;
  retentionPeriodEpoch: number;
  mediaStore;
  fetchMedia;
  generateTimestamp;
  dateNow: number;
}) {
  const { cameraId, retentionPeriodEpoch, mediaStore, fetchMedia, generateTimestamp, dateNow } = params;
  let { currentTime } = params;
  mediaStore.stopSearchingFutureRecordings = false;

  // we will stop fetching recordings when we find the closest recording
  // or when we reach the retention period
  do {
    const closestRecording = await fetchRecordingChunk({
      cameraId,
      currentTime,
      dateNow,
      generateTimestamp,
      fetchMedia,
    });
    if (closestRecording) return closestRecording;
    const oldCurrentTime = currentTime;
    currentTime += MEDIA_API_CHUNK_SIZE;
    if (oldCurrentTime === currentTime || currentTime > dateNow) break;
  } while (Math.abs(currentTime - dateNow) < retentionPeriodEpoch && !mediaStore.stopSearchingFutureRecordings);
}

/**
 * Fetches recordings for each camera and returns the closest recording to the current time.
 *
 * @param params - The parameters for fetching recordings.
 * @param params.cameraIds - The IDs of the cameras to fetch recordings for.
 * @param params.currentTime - The current time in milliseconds.
 * @param params.fetchMedia - The function to fetch media.
 * @param params.fetchCameraSettings - The function to fetch camera settings.
 * @param params.generateTimestamp - The function to generate a timestamp.
 * @param params.mediaStore - The store for camera media data.
 * @returns The closest recording to the current time.
 */
export async function fetchRecordingsForEachCamera(params: {
  cameraIds: string[];
  currentTime: number;
  fetchMedia: (params: mediaMainRequest) => Promise<ApiPaginatedMediaResponse | undefined>;
  fetchCameraSettings: (cameraId: string) => Promise<CameraSettingsResponse | undefined>;
  generateTimestamp: (params: string | number) => string;
  mediaStore: CameraMediaStore;
  dateNow: number;
}): Promise<ApiMediaWithIncludes | undefined> {
  const { cameraIds, mediaStore, currentTime, fetchMedia, fetchCameraSettings, generateTimestamp, dateNow } = params;

  const maxAvailableRetentionPeriods = await getMaxAvailableRetentionPeriods(cameraIds, fetchCameraSettings);
  const retentionPeriodPerCameraEpoch = maxAvailableRetentionPeriods.map((days) => days * DAY);

  const promises = cameraIds.map((cameraId, index) =>
    fetchUntilRecordingFound({
      cameraId,
      currentTime,
      retentionPeriodEpoch: retentionPeriodPerCameraEpoch[index],
      mediaStore,
      fetchMedia,
      generateTimestamp,
      dateNow,
    })
  );

  let closestRecording: ApiMediaWithIncludes | undefined;
  try {
    closestRecording = await Promise.race(promises);
  } catch (err) {
    if ((err as Error).name === 'AbortError') {
      console.log('Fetch aborted');
    } else {
      throw err;
    }
  }
  return closestRecording;
}

/**
 * Fetches the maximum available retention periods for each camera.
 * @param cameraIds - The IDs of the cameras to fetch the retention periods for.
 * @param fetchCameraSettings - The function to fetch camera settings.
 * @returns An array of the maximum available retention periods for each camera.
 */
export async function getMaxAvailableRetentionPeriods(
  cameraIds: string[],
  fetchCameraSettings: (cameraId: string) => Promise<CameraSettingsResponse | undefined>
): Promise<number[]> {
  return await Promise.all(
    cameraIds.map(async (cameraId) => {
      const cameraSettings = await fetchCameraSettings(cameraId);
      if (!(cameraSettings && cameraSettings?.data?.retention)) return DEFAULT_RETENTION_PERIOD_DAYS;
      // retention object has three value about measurement: cloudDays, maximumOnPremiseDays, minimumOnPremiseDays we pick the highest value
      return (
        Math.max(
          cameraSettings.data.retention?.cloudDays || 0,
          cameraSettings.data.retention?.maximumOnPremiseDays || 0,
          cameraSettings.data.retention?.minimumOnPremiseDays || 0
        ) + RETENTION_PERIOD_MARGIN_DAYS
      );
    })
  );
}
