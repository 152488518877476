import axios from 'axios';
import { rerouteToOAuthLogin } from '@eencloud/eewc-components/src/service/auth.js';
import { useMessagingStore, useAccountStore } from '@/stores';
import router from './router';
import useIframeAuth from '@/service/useIframeAuth';
import Sentry from '@/plugins/sentry';
import { introspectToken } from '@eencloud/eewc-components/src/service/auth';

export default function setupInterceptor() {
  axios.interceptors.response.use(undefined, async function (err) {
    if (err.response?.config.url.endsWith('/accounts/self/capabilities')) {
      if (checkAccountPermission(err.response)) return;
    }

    // Exclude some endpoints from error handling
    const ignoredEndpoints = ['/media/recordedImage.jpeg', '/g/analytics/snapshot', 'oauth2/introspect', '/g/collect'];
    if (!err.response || ignoredEndpoints.some((endpoint) => err.response?.config.url.includes(endpoint))) {
      return;
    }

    if (import.meta.env.PROD && err.response?.status > 401) {
      const error = new Error(
        `Api error code ${err.response.status}, Url:${err.response?.config.url}, message: ${err.response?.data.message}`
      );
      Sentry.captureException(error);
    }

    if (err.response?.status === 504 && err.response?.config.url.includes('/events')) return; // ignore 504 timeout error for /events
    await catch401Errors(err);

    const e = err.response?.data;

    const messagingStore = useMessagingStore();

    const notificationTitle =
      err.response?.status === 409 && err.response?.config.url === '/pos/oauth2/login'
        ? 'Connection status'
        : 'Something went wrong';

    messagingStore.addNotification({
      iconColor: 'negative',
      icon: '$icon_attention',
      title: notificationTitle,
      body: e.message,
    });

    // this is handle case when the respected camera is not present in the account and try to open HB/live with the camera
    if (
      err.response.config.params?.include === 'capabilities' &&
      err.response?.status === 404 &&
      err.response?.config.url.includes('/cameras/')
    ) {
      router.push({ name: '/' });
    }
    return Promise.reject(err);
  });
}

function checkAccountPermission(response) {
  if (response?.data.code === 403 && response.data?.status === 'permissionDenied') {
    const accountsStore = useAccountStore();
    accountsStore.updateAccountPermissionDeniedStatus(true);
    return true;
  }
  return false;
}

async function catch401Errors(err) {
  if (err.response?.status === 401) {
    const { active } = await introspectToken();
    // if the user is still authenticated, we create a new axios instance to retry the request once
    if (active) {
      const axiosInstance = axios.create();
      const result = await axiosInstance.request(err.config);
      const error = new Error(`Invalid 401, Url:${err.response?.config.url}`);
      Sentry.captureException(error);
      // Return the result to resolve the promise
      return result;
    } else {
      // if the user is not authenticated, we redirect to the login page
      window.$cookies.remove('access_token');
      window.$cookies.remove('auth_key');
      if (window.self !== window.top) {
        const { renewTokenViaIframeAncestor } = useIframeAuth();
        renewTokenViaIframeAncestor({
          name: router.currentRoute.path.name,
          params: router.currentRoute.params,
          query: router.currentRoute.query,
        });
      } else {
        rerouteToOAuthLogin();
      }
    }
  }
}
