const SensorsDashboard = () => import('@/pages/Sensors/Devices/SensorsDashboard.vue');
const SensorsValues = () => import('@/pages/Sensors/Values/SensorsValues.vue');
const SensorsHistory = () => import('@/pages/Sensors/History/SensorsHistory.vue');
const SensorsGatewayAdd = () => import('@/pages/Sensors/Gateways/SensorsGatewayAdd.vue');
const SensorsGatewaySettings = () => import('@/pages/Sensors/Gateways/SensorsGatewaySettings.vue');
const SensorsAvailableDevices = () => import('@/pages/Sensors/Devices/SensorsAvailableDevices.vue');
const SensorsDeviceAdd = () => import('@/pages/Sensors/Devices/SensorDeviceAdd.vue');
const SensorsDeviceSettings = () => import('@/pages/Sensors/Devices/SensorsDeviceSettings.vue');
const SensorsValuesSettings = () => import('@/pages/Sensors/Values/SensorsValuesSettings.vue');

export default [
  {
    path: '/sensors/devices',
    name: 'Sensors',
    component: SensorsDashboard,
    meta: {
      auth: true,
      rootRoute: true,
    },
  },
  {
    path: '/sensors/devices/add/:guid/model/:model',
    name: 'Add sensor device',
    component: SensorsDeviceAdd,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sensors/devices/add',
    name: 'Available sensor devices',
    component: SensorsAvailableDevices,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sensors/devices/:id',
    name: 'Sensor device settings',
    component: SensorsDeviceSettings,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sensors/values',
    name: 'Values',
    component: SensorsValues,
    meta: {
      auth: true,
      rootRoute: true,
    },
  },
  {
    path: '/sensors/settings/:id',
    name: 'Sensor settings',
    component: SensorsValuesSettings,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sensors/history',
    name: 'History',
    component: SensorsHistory,
    meta: {
      auth: true,
      rootRoute: true,
    },
  },
  {
    path: '/sensors/gateways/add',
    name: 'Add gateway',
    component: SensorsGatewayAdd,
    meta: {
      auth: true,
    },
  },
  {
    path: '/sensors/gateways/:id',
    name: 'Gateway settings',
    component: SensorsGatewaySettings,
    meta: {
      auth: true,
    },
  },
];
