import { useAppStateStore, useCamerasStore, useHistoryBrowserStore, useUsersStore, useRulesStore } from '@/stores';
import { Route } from 'vue-router';
import router from './router';
import _ from 'lodash';
import { watch, watchEffect, computed, ref } from 'vue';
import { t } from '@/plugins/i18n.ts';

type Dictionary<T> = { [key: string]: T };

export interface BreadcrumbItem {
  name: string | null | undefined;
  path?: string;
  tooltipText?: string;
  query?: Dictionary<string | (string | null)[]>;
  params?: Dictionary<string>;
}

interface BreadcrumbDetails {
  title: string;
}

export default function useTopBarBreadcrumbs(to: Route, from: Route) {
  const appStateStore = useAppStateStore();
  const hbStore = useHistoryBrowserStore();
  const camerasStore = useCamerasStore();
  const rulesStore = useRulesStore();
  let initCall = true;
  const breadcrumbDetails = ref<BreadcrumbDetails>();

  const defaultName = computed(() => (to.name ? t(to.name) : ''));
  // for first level routes, no need to show the breadcrumbs
  if (to.name && to.meta?.rootRoute) {
    appStateStore.changeTopBarBreadcrumbs(undefined);
    return;
  }

  watchEffect(async () => {
    // we cant use onMounted lifecycle because it is not properly implemented with a component
    // so we are using this flag to trigger getDetails only when it is refreshed
    if (!initCall) return;
    initCall = false;
    breadcrumbDetails.value = await getDetails();
  });

  watch(
    // this has been added to display the updated details in breadcrumb region
    () => breadcrumbDetails.value,
    (newVal, oldVal) => {
      if (!_.isEqual(newVal, oldVal)) {
        updateBreadcrumbs();
      }
    }
  );

  async function updateBreadcrumbs() {
    appStateStore.changeTopBarBreadcrumbs({
      from: from.meta?.tooltipText || from?.name ? `${t('Back to')} ${from.meta?.tooltipText ?? from.name}` : '',
      title: breadcrumbDetails.value?.title ?? '',
      backEvent: () => router.go(-1),
    });
  }

  /**
   * this function is specifically for history browser routes to handle case for single camera, multi-camera view and hb+live
   *
   * @param toRoute
   * @returns
   */
  function handleHistoryBrowserCase() {
    if (hbStore.customOrder && hbStore.customOrder?.length > 1) {
      if (!hbStore.customOrder.toString().includes('live')) {
        return { title: t('Multi-Camera Playback') };
      } else return { title: t('History browser + Live view') };
    } else {
      hbStore.customOrder = hbStore.cameraIds;
      let camera;
      if (camerasStore.pagedCameras.length) {
        camera = camerasStore.pagedCameras.find((camera) => camera.id === hbStore.cameraIds[0]);
      }
      if (camerasStore.allCamerasByCustomParam.length) {
        camera = camerasStore.allCamerasByCustomParam.find((camera) => camera.id === hbStore.cameraIds[0]);
      }
      return {
        title: camera?.name ?? '',
      };
    }
  }

  async function getDetails(): Promise<BreadcrumbDetails> {
    const resourceId = to.params?.id;
    const resourceName = to.params?.name;

    switch (to.name) {
      case 'History Browser': {
        return handleHistoryBrowserCase();
      }

      case 'User settings': {
        if (!resourceId) return { title: defaultName.value };
        const usersStore = useUsersStore();
        const { firstName, lastName } = await usersStore.getUser(resourceId, { include: undefined });
        return {
          title: firstName ? `${firstName} ${lastName}` : defaultName.value,
        };
      }

      case 'Video': {
        if (resourceId) {
          const camera = await camerasStore.getCamera(resourceId);
          return {
            title: camera?.name ?? defaultName.value,
          };
        } else return { title: defaultName.value };
      }

      case 'Add camera': {
        return {
          title: resourceName ? `${t('Add camera')} • ${resourceName}` ?? defaultName.value : defaultName.value,
        };
      }

      case 'Edit rule': {
        return {
          title:
            resourceId && rulesStore.currentRuleName
              ? `${t('Edit rule')} • ${rulesStore.currentRuleName}`
              : defaultName.value,
        };
      }

      case 'FaceSearch': {
        return {
          title: `${t('VideoSearch')}  • ${t('Face')}`,
        };
      }

      case 'Location': {
        return {
          title: '',
        };
      }

      default:
        return { title: defaultName.value };
    }
  }
}
