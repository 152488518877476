import api from '@eencloud/eewc-components/src/service/api';
import {
  ApiLayout,
  ApiLayoutCreate,
  ApiLayoutUpdate,
  GetLayoutsParams,
} from '@eencloud/eewc-components/src/service/api-types';
import { SettingsType } from '@/pages/Layouts/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import router from '../service/router';
import { useMessagingStore, useCamerasStore, useAppStateStore } from '../stores';
import _ from 'lodash';
import { t, tc } from '@/plugins/i18n.ts';
import { IntersectionStateChanger } from '@eencloud/eewc-components/src/utils/types';

export const useLayoutsStore = defineStore('layouts', function () {
  const allLayoutsByCustomParam = ref<ApiLayout[]>([]);
  const loadingLayouts = ref<boolean | undefined>(undefined);
  const selectedLayout = ref<ApiLayout | null>(null);
  const totalLayoutsSize = ref<number | undefined>();
  const messagingStore = useMessagingStore();
  const layoutRotationOnHold = ref<boolean>(false);
  const pagedLayouts = ref<ApiLayout[]>([]); // this list represents the layouts in the navigation sidebar
  const pagedLayoutsLoading = ref<boolean>(false);
  const pagedLayoutsNextPageToken = ref<string | undefined>(undefined);
  const pagedLayoutInfiniteState = ref<IntersectionStateChanger>();
  const camerasStore = useCamerasStore();
  const appStateStore = useAppStateStore();

  function selectLayout(layout: ApiLayout | null) {
    selectedLayout.value = layout;
  }

  /**
   * @param params GetLayoutsParams
   * @description The function will fetch all layouts and save it to allLayoutsByCustomParam.
   * Be careful with this function It is used in multiple places and It overrides the allLayoutsByCustomParam variable.
   */
  async function getAllLayoutsByCustomParam(params: GetLayoutsParams) {
    let layoutsList: ApiLayout[] = [];
    loadingLayouts.value = true;
    allLayoutsByCustomParam.value = [];
    try {
      let pageToken: string | undefined;
      do {
        const data = await api.getLayouts({
          pageToken: pageToken as string,
          pageSize: 500,
          ...params,
          include: params.include ? params.include.toString() : undefined,
          sort: params.sort?.toString() ?? undefined, //to fetch the layouts in the sorted order
        });
        if (data) {
          pageToken = data.nextPageToken;
          totalLayoutsSize.value = data.totalSize;
          layoutsList = [...layoutsList, ...(data.results as ApiLayout[])];
        } else break;
      } while (pageToken !== '');
    } catch (error) {
      console.error(error);
    }
    allLayoutsByCustomParam.value = layoutsList;
    loadingLayouts.value = false;
  }

  async function getLayout(layoutId: string, include?: string) {
    try {
      return await api.getLayouts({ layoutId, include });
    } catch (error) {
      console.error(error);
    }
  }

  async function deleteLayout(layoutId: string) {
    try {
      await api.deleteLayouts(layoutId);
      window.gtag('event', 'Layout_Deleted');
      if (router.currentRoute.name === 'Layouts') {
        // show "all layouts" after we delete the currently selected layout
        router.push({ name: 'Layouts', params: { id: 'all' } });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function createLayout(payload: ApiLayoutCreate) {
    try {
      const newLayout = await api.createLayout(payload);
      if (!newLayout) {
        throw new Error();
      }
      const notificationBodyText = t('The {nameOfLayout} layout is created.', {
        nameOfLayout: `<span class='font-weight-medium'>${newLayout.name}</span>`,
      });
      messagingStore.showSuccessMessage(notificationBodyText);
      window.gtag('event', 'Layout_Created');
      if (router.currentRoute.name === 'Layouts') {
        router.push({ name: 'Layouts', params: { id: newLayout.id } });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getPagedLayouts(params: GetLayoutsParams) {
    let layouts: ApiLayout[] = [];
    let nextPageToken = '';
    let totalSize = 0;
    try {
      const data = await api.getLayouts(params);
      if (data) {
        nextPageToken = data?.nextPageToken ?? '';
        totalSize = data.totalSize ?? 0;
        totalLayoutsSize.value = data.totalSize ?? 0;
        layouts = data.results as ApiLayout[];
      }
    } catch (error) {
      console.error(error);
    }
    return { layouts, totalSize, nextPageToken };
  }

  async function updateLayout(
    layout?: ApiLayoutUpdate & {
      id: string;
    },
    settingsType?: SettingsType,
    newCameraCount?: number
  ) {
    try {
      const settings = structuredClone(layout?.settings);
      const panes = structuredClone(layout?.panes);
      const layoutId = layout?.id;
      const effectivePermissions = selectedLayout.value?.effectivePermissions;
      const name = selectedLayout.value?.name;

      const status = await api.updateLayout({
        layoutId,
        payload: { panes, settings },
      });
      if (status !== 204) {
        throw new Error();
      }

      const notificationBody =
        settingsType === 'Add cameras'
          ? tc('Cameras were added to the {layoutName} layout.', newCameraCount, {
              layoutName: `<span class="font-weight-medium">${name}</span>`,
            })
          : t('Your changes have been saved.');

      messagingStore.addNotification({
        iconColor: 'positive',
        icon: '$icon_check_zero',
        title: t('Success'),
        body: notificationBody,
      });

      // refetch the cameras if those where changed, because all cameras must be present
      const panesChanged = JSON.stringify(selectedLayout.value?.panes) !== JSON.stringify(panes);
      if (panesChanged) {
        camerasStore.getAllCamerasByCustomParam({
          layoutId: layoutId,
          q: appStateStore.searchQuery.length ? appStateStore.searchQuery : undefined,
          include: ['status', 'timeZone', 'effectivePermissions', 'capabilities'],
        });
      }

      if (selectedLayout.value?.id === layoutId) {
        selectedLayout.value = { ...selectedLayout.value, settings, panes };
      }
      updateLayoutInStore({ id: layoutId, settings, panes, name, effectivePermissions });

      window.gtag('event', 'Layout_Updated');
    } catch (error) {
      console.error(error);
    }
  }

  function deleteLayoutFromStore(layoutId: string) {
    pagedLayouts.value = pagedLayouts.value.filter((layout) => layout.id !== layoutId);
  }

  function updateLayoutInStore(layout: ApiLayout) {
    const { id, ...newParams } = layout;
    pagedLayouts.value = pagedLayouts.value.map((layout) => (layout.id === id ? { ...layout, ...newParams } : layout));
    // update the selected layout
    if (selectedLayout.value?.id === id) {
      selectedLayout.value = layout;
    }
  }

  function resetPagedLayouts() {
    pagedLayouts.value = [];
    pagedLayoutsNextPageToken.value = undefined;
    if (pagedLayoutInfiniteState.value) pagedLayoutInfiniteState.value.reset();
  }

  function removeCameraFromSelectedLayout(cameraIds: string[]) {
    if (!selectedLayout.value || !selectedLayout.value.panes) {
      return;
    }
    const layout: ApiLayout = {
      ...selectedLayout.value,
      panes: selectedLayout.value.panes.filter((p) => !cameraIds.includes(p.cameraId)),
    };

    updateLayout(layout);
  }

  function updateLayoutRotationOnHold(state: boolean) {
    layoutRotationOnHold.value = state;
  }

  return {
    totalLayoutsSize,
    getAllLayoutsByCustomParam,
    allLayoutsByCustomParam,
    loadingLayouts,
    selectedLayout,
    selectLayout,
    getLayout,
    getPagedLayouts,
    deleteLayout,
    createLayout,
    updateLayout,
    deleteLayoutFromStore,
    removeCameraFromSelectedLayout,
    updateLayoutInStore,
    layoutRotationOnHold,
    updateLayoutRotationOnHold,
    pagedLayouts,
    pagedLayoutsLoading,
    pagedLayoutsNextPageToken,
    pagedLayoutInfiniteState,
    resetPagedLayouts,
  };
});
